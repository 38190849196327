import {
    POST_UPLOAD_FILE} from '../constants/ActionTypes';
    
      const INIT_STATE = {
          uploadFileData: null,
        };
        
        export default (state = INIT_STATE, action) => {
            switch(action.type) {
              case POST_UPLOAD_FILE:
                       return {
                         ...state,
                         uploadFileData: action.payload
                    }
            default:
                  return state;
            }
        }
        