import {
   ADD_VEHICLE_INSURANCE_CHECK,
   EDIT_VEHICLE_INSURANCE_CHECK,
   SINGLE_VEHICLE_INSURANCE_CHECK,
   FETCH_VEHICLES_INSURANCE_CHECK,
   DELETE_VEHICLE_INSURANCE_CHECK
  }
from '../constants/ActionTypes';

const INIT_STATE = {
  addVehicleInsuranceCheck: null,
  editVehicleInsuranceCheck: null,
  singleVehicleInsuranceCheck: null,
  vehiclesInsuranceCheckData: null,
  deleteVehicleInsuranceCheck: null
};

export default (state = INIT_STATE, action) => {
    switch(action.type) {
      case ADD_VEHICLE_INSURANCE_CHECK:
               return {
                 ...state,
               addVehicleInsuranceCheck: action.payload
            }
      case EDIT_VEHICLE_INSURANCE_CHECK:
                 return {
                 ...state,
                 editVehicleInsuranceCheck: action.payload
          }

      case SINGLE_VEHICLE_INSURANCE_CHECK:
            return {
              ...state,
              singleVehicleInsuranceCheck: action.payload
        }
      case FETCH_VEHICLES_INSURANCE_CHECK:
               return {
                 ...state,
               vehiclesInsuranceCheckData: action.payload
      }
      case DELETE_VEHICLE_INSURANCE_CHECK:
            return {
              ...state,
              deleteVehicleInsuranceCheck: action.payload
      }
    default:
          return state;
    }
}
