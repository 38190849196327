//custom app constants
export const GENERAL_ERROR = "Παρουσιάστηκε σφάλμα. Παρακαλώ προσπαθήστε ξανά αργότερα.";
export const DEBUG_MODE = true;



//FIREBAE TOPICS
export const ARG_FIREBASE_TOPIC_USER = "topic_user_"
export const ARG_FIREBASE_TOPIC_ALL = "topic_user_all"


export const DATE_FORMAT = "DD/MM/yyyy";

export const genders = [
  {
    value: '1',
    label: 'Άνδρας',
  },
  {
    value: '2',
    label: 'Γυναίκα'
  },
];

export const roles = [
  {
    value: '1',
    label: 'Admin',
  },
  {
    value: '2',
    label: 'Manager'
  }
];

export const userstatuses = [
  {
    value: true,
    label: 'Ενεργός',
  },
  {
    value: false,
    label: 'Ανενεργός'
  }
]

export const serviceTypes = [
  {
    value: '1',
    label: 'Επαναλαμβανόμενη',
  },
  {
    value: '2',
    label: 'Περιοδική'
  },
  {
    value: '3',
    label: 'Μεμονωμένη'
  }
];

export const serviceStatuses = [
  {
    value: true,
    label: 'Ενεργή',
  },
  {
    value: false,
    label: 'Ανενεργή'
  }
];

export const estateStatuses = [
  {
    value: true,
    label: 'Ενεργή',
  },
  {
    value: false,
    label: 'Ανενεργή'
  }
];


export const days = [
  {
    value: '1',
    label: 'Δευτέρα',
  },
  {
    value: '2',
    label: 'Τρίτη'
  },
  {
    value: '3',
    label: 'Τετάρτη',
  },
  {
    value: '4',
    label: 'Πέμπτη'
  },
  {
    value: '5',
    label: 'Παρασκευή',
  },
  {
    value: '6',
    label: 'Σάββατο'
  },
  {
    value: '7',
    label: 'Κυριακή'
  }
];

export const contactMethod = [
  {
    value: '1',
    label: 'Σταθερό',
  },
  {
    value: '2',
    label: 'Κινητό'
  },
  {
    value: '3',
    label: 'Email'
  }
];
