import {FETCH_CLIENT_REMINDERS,
  CREATE_CLIENT_REMINDER,
  EDIT_CLIENT_REMINDER,
  SINGLE_CLIENT_REMINDER,
  DELETE_CLIENT_REMINDER} from '../constants/ActionTypes';

const INIT_STATE = {
  clientReminders: null,
  createClientReminder: null,
  editClientReminder: null,
  singleClientReminder: null,
  deleteClientReminder: null
};

export default (state = INIT_STATE, action) => {
  switch(action.type) {
   case FETCH_CLIENT_REMINDERS:
       return {
           ...state,
           clientReminders: action.payload
       }
   case CREATE_CLIENT_REMINDER:
        return {
            ...state,
            createClientReminder: action.payload
        }
   case EDIT_CLIENT_REMINDER:
         return {
             ...state,
             editClientReminder: action.payload
         }
  case SINGLE_CLIENT_REMINDER:
         return {
             ...state,
             singleClientReminder: action.payload
         }
 case DELETE_CLIENT_REMINDER:
          return {
            ...state,
            deleteClientReminder: action.payload
          }
  default:
        return state;
  }
}
