import {
   ADD_ESTATE_KEY_CATEGORY,
   EDIT_ESTATE_KEY_CATEGORY,
   DELETE_ESTATE_KEY_CATEGORY,
   FETCH_ESTATE_KEYS_CATEGORIES,
   ADD_ESTATE_KEY,
   EDIT_ESTATE_KEY,
   FETCH_ESTATE_KEYS,
   DELETE_ESTATE_KEY
  }
from '../constants/ActionTypes';

const INIT_STATE = {
  addEstateKeyCategory: null,
  editEstateKeyCategory: null,
  deleteEstateKeyCategory: null,
  estateKeysCategoriesData: null,
  addEstateKey: null,
  editEstateKey: null,
  deleteEstateKey: null,
  estateKeysData: null
};

export default (state = INIT_STATE, action) => {
  switch(action.type) {
   case ADD_ESTATE_KEY_CATEGORY:
       return {
           ...state,
           addEstateKeyCategory: action.payload
       }
   case EDIT_ESTATE_KEY_CATEGORY:
       return {
           ...state,
           editEstateKeyCategory: action.payload
       }
  case DELETE_ESTATE_KEY_CATEGORY:
        return {
             ...state,
             deleteEstateKeyCategory: action.payload
      }
   case FETCH_ESTATE_KEYS_CATEGORIES:
        return {
            ...state,
            estateKeysCategoriesData: action.payload
        }
  case ADD_ESTATE_KEY:
           return {
             ...state,
           addEstateKey: action.payload
        }
  case EDIT_ESTATE_KEY:
             return {
             ...state,
             editEstateKey: action.payload
      }
  case DELETE_ESTATE_KEY:
             return {
             ...state,
             deleteEstateKey: action.payload
     }
  case FETCH_ESTATE_KEYS:
             return {
             ...state,
             estateKeysData: action.payload
      }
  default:
        return state;
  }
}
