import {
   ADD_STORE_AUTH_HOLDER,
   EDIT_STORE_AUTH_HOLDER,
   SINGLE_STORE_AUTH_HOLDER,
   FETCH_STORE_AUTH_HOLDER,
   DELETE_STORE_AUTH_HOLDER
  }
from '../constants/ActionTypes';

const INIT_STATE = {
  addStoreAuthHolder: null,
  editStoreAuthHolder: null,
  singleStoreAuthHolder: null,
  storeAuthHolderData: null,
  deleteStoreAuthHolder: null
};

export default (state = INIT_STATE, action) => {
    switch(action.type) {
      case ADD_STORE_AUTH_HOLDER:
               return {
                 ...state,
               addStoreAuthHolder: action.payload
            }
      case EDIT_STORE_AUTH_HOLDER:
                 return {
                 ...state,
                 editStoreAuthHolder: action.payload
          }

      case SINGLE_STORE_AUTH_HOLDER:
            return {
              ...state,
              singleStoreAuthHolder: action.payload
        }
      case FETCH_STORE_AUTH_HOLDER:
               return {
                 ...state,
               storeAuthHolderData: action.payload
      }
      case DELETE_STORE_AUTH_HOLDER:
            return {
              ...state,
              deleteStoreAuthHolder: action.payload
      }
    default:
          return state;
    }
}
