import {
   ADD_STORE_SUPPLIER,
   EDIT_STORE_SUPPLIER,
   SINGLE_STORE_SUPPLIER,
   FETCH_STORE_SUPPLIER,
   DELETE_STORE_SUPPLIER
  }
from '../constants/ActionTypes';

const INIT_STATE = {
  addStoreSupplier: null,
  editStoreSupplier: null,
  singleStoreSupplier: null,
  storeSupplierData: null,
  deleteStoreSupplier: null
};

export default (state = INIT_STATE, action) => {
    switch(action.type) {
      case ADD_STORE_SUPPLIER:
               return {
                 ...state,
               addStoreSupplier: action.payload
            }
      case EDIT_STORE_SUPPLIER:
                 return {
                 ...state,
                 editStoreSupplier: action.payload
          }

      case SINGLE_STORE_SUPPLIER:
            return {
              ...state,
              singleStoreSupplier: action.payload
        }
      case FETCH_STORE_SUPPLIER:
               return {
                 ...state,
               storeSupplierData: action.payload
      }
      case DELETE_STORE_SUPPLIER:
            return {
              ...state,
              deleteStoreSupplier: action.payload
      }
    default:
          return state;
    }
}
