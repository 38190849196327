


module.exports =  {
  getLocale:function() {
    try{
      const { getUserLocales } = require('get-user-locale');
      const { USER_LOCALE } = require("constants/ActionTypes");

      var userLocale =  localStorage.getItem(USER_LOCALE);
      if (userLocale == null || userLocale == ""){
        userLocale = getUserLocales()[0];
      }
      if (userLocale.includes("-")){
        userLocale = userLocale.split('-')[0];
      }
    return userLocale;
    } catch(ex) {
      return "en";
    }
  },
  getISOWeekInMonth:function(d) {
    var date = d.getDate();
    var day = d.getDay();

    return Math.ceil((date + 6 - day)/7);
  },
  getISOMonth: function(d){
    return d.getMonth() + 1;
  },
  getISOYear: function(d){
    return d.getFullYear();
  },
  getThisWeekDates: function(current) {
    var week= new Array();
    // Starting Monday not Sunday
    current.setDate((current.getDate() - current.getDay() +1));
    for (var i = 0; i < 7; i++) {
        week.push(
            new Date(current)
        );
        current.setDate(current.getDate() +1);
    }
    return week;
  },
  getDaysInMonth: function(month, year) {
    var date = new Date(year, month-1, 1);
    var days = [];
    while (date.getMonth() == month-1) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
  },
   withHttp:function(url) { 
    return !/^https?:\/\//i.test(url) ? `http://${url}` : url
  },
  allCapital: function(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();
  }

};
