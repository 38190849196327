import {
   ADD_VEHICLE_OIL_CHECK,
   EDIT_VEHICLE_OIL_CHECK,
   SINGLE_VEHICLE_OIL_CHECK,
   FETCH_VEHICLES_OIL_CHECK,
   DELETE_VEHICLE_OIL_CHECK
  }
from '../constants/ActionTypes';

const INIT_STATE = {
  addVehicleOilCheck: null,
  editVehicleOilCheck: null,
  singleVehicleOilCheck: null,
  vehiclesOilCheckData: null,
  deleteVehicleOilCheck: null
};

export default (state = INIT_STATE, action) => {
    switch(action.type) {
      case ADD_VEHICLE_OIL_CHECK:
               return {
                 ...state,
               addVehicleOilCheck: action.payload
            }
      case EDIT_VEHICLE_OIL_CHECK:
                 return {
                 ...state,
                 editVehicleOilCheck: action.payload
          }

      case SINGLE_VEHICLE_OIL_CHECK:
            return {
              ...state,
              singleVehicleOilCheck: action.payload
        }
      case FETCH_VEHICLES_OIL_CHECK:
               return {
                 ...state,
               vehiclesOilCheckData: action.payload
      }
      case DELETE_VEHICLE_OIL_CHECK:
            return {
              ...state,
              deleteVehicleOilCheck: action.payload
      }
    default:
          return state;
    }
}
