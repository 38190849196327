import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    INIT_URL,
    SHOW_MESSAGE,
    ADD_TRANSLATION,
    EDIT_TRANSLATION,
    FETCH_TRANSLATIONS,
    FETCH_ADMIN_TRANSLATIONS,
    DELETE_TRANSLATION,
    STORED_TRANSLATIONS,
    USER_ID_SET
  } from 'constants/ActionTypes';
  import General from 'util/general';

  import axios from 'util/Api'
  
  
  export const setInitUrl = (url) => {
    return {
      type: INIT_URL,
      payload: url
    };
  };

  export const getAdminTranslations = () => {
    //Get user locale of the machine he is on
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    let postData = {userId, sort: {"_id": -1}};
    return (dispatch) => {
      dispatch({type: FETCH_START});
      axios.post('admin/translations', postData
      ).then(({data}) => {
        if (data){
          console.log(data);
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: FETCH_ADMIN_TRANSLATIONS, payload: data});
        } else {
          dispatch({type: FETCH_ERROR, payload: data.error});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };
  
  export const getTranslations = () => {
      //Get user locale of the machine he is on
    const userLocale = General.getLocale();
    let postData = {langLoc: userLocale};

    return (dispatch) => {
      dispatch({type: FETCH_START});
      axios.post('settings/trans', postData
      ).then(({data}) => {
        if (data){
          localStorage.setItem(STORED_TRANSLATIONS, JSON.stringify(data.trans));
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: FETCH_TRANSLATIONS, payload: data.trans});
        } else {
          dispatch({type: FETCH_ERROR, payload: data.error});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };
  
  export const addTranslation = (postData) => {
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    postData.userId = userId;
    return (dispatch) => {
      dispatch({type: FETCH_START});
      axios.post('admin/add-translation', postData
      ).then(({data}) => {
        console.log(data);
        if (data){
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: ADD_TRANSLATION, payload: data});
        } else {
          dispatch({type: FETCH_ERROR, payload: data.error});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };
  
  export const editTranslation = (postData) => {
    return (dispatch) => {
      dispatch({type: FETCH_START});
      axios.post('admin/edit-translation', postData
      ).then(({data}) => {
        if (data){
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: EDIT_TRANSLATION, payload: data});
        } else {
          dispatch({type: FETCH_ERROR, payload: data.error});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };
  
  export const deleteTranslation = (postData) => {
    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    postData.userId = userId;
    return (dispatch) => {
      dispatch({type: FETCH_START});
      axios.post('admin/delete-translation', postData
      ).then(({data}) => {
        if (data){
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: DELETE_TRANSLATION, payload: data});
        } else {
          dispatch({type: FETCH_ERROR, payload: data.error});
        }
      }).catch(function (error) {
        dispatch({type: FETCH_ERROR, payload: error.message});
        console.log("Error****:", error.message);
      });
    }
  };
  