import {ADD_BILL_CHECK, ADD_BILL_CHECK_PAY, DELETE_BILL_CHECK, DELETE_BILL_CHECK_PAY } from '../constants/ActionTypes';

const INIT_STATE = {
  addBillCheckData: null,
  addBillCheckPayData: null,
  deleteBillCheckData: null,
  deleteBillCheckPayData: null
};

export default (state = INIT_STATE, action) => {
  switch(action.type) {
   case ADD_BILL_CHECK:
       return {
           ...state,
           addBillCheckData: action.payload
       }
   case ADD_BILL_CHECK_PAY:
        return {
            ...state,
            addBillCheckPayData: action.payload
        }
        case DELETE_BILL_CHECK:
             return {
                 ...state,
                 deleteBillCheckData: action.payload
             }
             case DELETE_BILL_CHECK_PAY:
                  return {
                      ...state,
                      deleteBillCheckPayData: action.payload
                  }
  default:
        return state;
  }
}
