import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import {userSignIn} from 'actions/Auth';
import CircularProgress from "@material-ui/core/CircularProgress";
import {NotificationContainer, NotificationManager} from "react-notifications";

const SignIn = (props) => {
  //apps@focus-on.gr //12345678
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const token = useSelector(({auth}) => auth.token);
  const {loading, message} = useSelector(({common}) => common);
  const trans = useSelector(({translation}) => translation.translationsData);

  
  useEffect(() => {
    if (token !== null) {
      props.history.push('/');
    }
  }, [message,token]);

  return (
    <div
      className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">

        <div className="app-logo-content d-flex align-items-center justify-content-center">
          <Link className="logo-lg" to="/" title="FOCUS-ON Team">
            <img style={{width:'100%'}} src={require("assets/images/logo_white.png")} alt="focusonteam" title="focusonteam"/>
          </Link>
        </div>

        <div className="app-login-content">
          <div className="app-login-header mb-4">
            <h1>{trans.sign_in_title}</h1>
          </div>

          <div className="app-login-form">
            <form>
              <fieldset>
                <TextField
                  label={<IntlMessages id="appModule.email"/>}
                  fullWidth
                  onChange={(event) => setEmail(event.target.value)}
                  defaultValue={email}
                  margin="normal"
                  className="mt-1 my-sm-3"
                />
                <TextField
                  type="password"
                  label={trans.password_field}
                  fullWidth
                  onChange={(event) => setPassword(event.target.value)}
                  defaultValue={password}
                  margin="normal"
                  className="mt-1 my-sm-3"
                />

                <div className="mb-3 d-flex align-items-center justify-content-between">
                  <Button style={{ borderRadius: 20 }} onClick={() => {
                    dispatch(userSignIn({email, password}));
                  }} variant="contained" color="primary">
                    {trans.sign_in_btn}
                  </Button>


                </div>

              </fieldset>
            </form>
          </div>
        </div>
      </div>

      {
        loading &&
        <div className="loader-view">
          <CircularProgress/>
        </div>
      }
      {message && NotificationManager.error(message)}
      <NotificationContainer/>

    </div>
  );
};


export default SignIn;
