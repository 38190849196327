import {
   ADD_STORE_ORDER,
   EDIT_STORE_ORDER,
   SINGLE_STORE_ORDER,
   FETCH_STORE_ORDER,
   DELETE_STORE_ORDER
  }
from '../constants/ActionTypes';

const INIT_STATE = {
  addStoreOrder: null,
  editStoreOrder: null,
  singleStoreOrder: null,
  storeOrderData: null,
  deleteStoreOrder: null
};

export default (state = INIT_STATE, action) => {
    switch(action.type) {
      case ADD_STORE_ORDER:
               return {
                 ...state,
               addStoreOrder: action.payload
            }
      case EDIT_STORE_ORDER:
                 return {
                 ...state,
                 editStoreOrder: action.payload
          }

      case SINGLE_STORE_ORDER:
            return {
              ...state,
              singleStoreOrder: action.payload
        }
      case FETCH_STORE_ORDER:
               return {
                 ...state,
               storeOrderData: action.payload
      }
      case DELETE_STORE_ORDER:
            return {
              ...state,
              deleteStoreOrder: action.payload
      }
    default:
          return state;
    }
}
