import {
   ADD_VEHICLE_MOVEMENT,
   EDIT_VEHICLE_MOVEMENT,
   SINGLE_VEHICLE_MOVEMENT,
   FETCH_VEHICLES_MOVEMENT,
   DELETE_VEHICLE_MOVEMENT
  }
from '../constants/ActionTypes';

const INIT_STATE = {
  addVehicleMovement: null,
  editVehicleMovement: null,
  singleVehicleMovement: null,
  vehiclesMovementData: null,
  deleteVehicleMovement: null
};

export default (state = INIT_STATE, action) => {
    switch(action.type) {
      case ADD_VEHICLE_MOVEMENT:
               return {
                 ...state,
               addVehicleMovement: action.payload
            }
      case EDIT_VEHICLE_MOVEMENT:
                 return {
                 ...state,
                 editVehicleMovement: action.payload
          }

      case SINGLE_VEHICLE_MOVEMENT:
            return {
              ...state,
              singleVehicleMovement: action.payload
        }
      case FETCH_VEHICLES_MOVEMENT:
               return {
                 ...state,
               vehiclesMovementData: action.payload
      }
      case DELETE_VEHICLE_MOVEMENT:
            return {
              ...state,
              deleteVehicleMovement: action.payload
      }
    default:
          return state;
    }
}
