import {
   ADD_ESTATE_SERVICE_TASK,
   EDIT_ESTATE_SERVICE_TASK,
   SINGLE_ESTATE_SERVICE_TASK,
   FETCH_ESTATE_SERVICE_TASKS,
   DELETE_ESTATE_SERVICE_TASK
  }
from '../constants/ActionTypes';

const INIT_STATE = {
  addEstateServiceTask: null,
  editEstateServiceTask: null,
  singleEstateServiceTask: null,
  estateServiceDataTask: null,
  deleteEstateServiceTask: null
};

export default (state = INIT_STATE, action) => {
    switch(action.type) {
      case ADD_ESTATE_SERVICE_TASK:
               return {
                 ...state,
               addEstateServiceTask: action.payload
            }
      case EDIT_ESTATE_SERVICE_TASK:
                 return {
                 ...state,
                 editEstateServiceTask: action.payload
          }

      case SINGLE_ESTATE_SERVICE_TASK:
            return {
              ...state,
              singleEstateServiceTask: action.payload
        }
      case FETCH_ESTATE_SERVICE_TASKS:
               return {
                 ...state,
               estateServiceDataTask: action.payload
      }
      case DELETE_ESTATE_SERVICE_TASK:
            return {
              ...state,
              deleteEstateServiceTask: action.payload
      }
    default:
          return state;
    }
}
