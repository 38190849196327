import {CREATE_CLIENT,EDIT_CLIENT,SINGLE_CLIENT,ALL_CLIENT} from '../constants/ActionTypes';

const INIT_STATE = {
  allClient: null,
  createClient: null,
  editClient: null,
  singleClient: null
};

export default (state = INIT_STATE, action) => {
  switch(action.type) {
   case ALL_CLIENT:
       return {
           ...state,
           allClient: action.payload
       }
   case CREATE_CLIENT:
        return {
            ...state,
            createClient: action.payload
        }
   case EDIT_CLIENT:
         return {
             ...state,
             editClient: action.payload
         }
  case SINGLE_CLIENT:
         return {
             ...state,
             singleClient: action.payload
         }
  default:
        return state;
  }
}
