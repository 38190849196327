import {ADD_REMINDER,
  EDIT_REMINDER,
  SINGLE_REMINDER,
  FETCH_REMINDERS,
  DELETE_REMINDER
  } from '../constants/ActionTypes';

const INIT_STATE = {
  addReminder: null,
  editReminder: null,
  singleReminder: null,
  reminders: null,
  deleteReminder: null
};

export default (state = INIT_STATE, action) => {
  switch(action.type) {
   case ADD_REMINDER:
       return {
           ...state,
           addReminder: action.payload
       }
   case EDIT_REMINDER:
        return {
            ...state,
            editReminder: action.payload
        }
  case SINGLE_REMINDER:
       return {
         ...state,
         singleReminder: action.payload
       }
  case FETCH_REMINDERS:
      return {
          ...state,
        reminders: action.payload
        }
  case DELETE_REMINDER:
        return {
          ...state,
        deleteReminder: action.payload
    }
  default:
        return state;
  }
}
