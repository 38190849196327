import {
   ADD_STORE_PRODUCT,
   EDIT_STORE_PRODUCT,
   SINGLE_STORE_PRODUCT,
   FETCH_STORE_PRODUCT,
   DELETE_STORE_PRODUCT
  }
from '../constants/ActionTypes';

const INIT_STATE = {
  addStoreProduct: null,
  editStoreProduct: null,
  singleStoreProduct: null,
  storeProductData: null,
  deleteStoreProduct: null
};

export default (state = INIT_STATE, action) => {
    switch(action.type) {
      case ADD_STORE_PRODUCT:
               return {
                 ...state,
               addStoreProduct: action.payload
            }
      case EDIT_STORE_PRODUCT:
                 return {
                 ...state,
                 editStoreProduct: action.payload
          }

      case SINGLE_STORE_PRODUCT:
            return {
              ...state,
              singleStoreProduct: action.payload
        }
      case FETCH_STORE_PRODUCT:
               return {
                 ...state,
               storeProductData: action.payload
      }
      case DELETE_STORE_PRODUCT:
            return {
              ...state,
              deleteStoreProduct: action.payload
      }
    default:
          return state;
    }
}
