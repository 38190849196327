import {
   ADD_STORE_SELLER,
   EDIT_STORE_SELLER,
   SINGLE_STORE_SELLER,
   FETCH_STORE_SELLER,
   DELETE_STORE_SELLER
  }
from '../constants/ActionTypes';

const INIT_STATE = {
  addStoreSeller: null,
  editStoreSeller: null,
  singleStoreSeller: null,
  storeSellerData: null,
  deleteStoreSeller: null
};

export default (state = INIT_STATE, action) => {
    switch(action.type) {
      case ADD_STORE_SELLER:
               return {
                 ...state,
               addStoreSeller: action.payload
            }
      case EDIT_STORE_SELLER:
                 return {
                 ...state,
                 editStoreSeller: action.payload
          }

      case SINGLE_STORE_SELLER:
            return {
              ...state,
              singleStoreSeller: action.payload
        }
      case FETCH_STORE_SELLER:
               return {
                 ...state,
               storeSellerData: action.payload
      }
      case DELETE_STORE_SELLER:
            return {
              ...state,
              deleteStoreSeller: action.payload
      }
    default:
          return state;
    }
}
