import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  SHOW_MESSAGE,
  FETCH_DATA,
  CREATE_USER_SUCCESS,
  RESET_PASS,
  USER_IS_MANAGER,
  USER_IS_ADMIN
} from "../constants/ActionTypes";
import axios from 'util/Api'

import {GENERAL_ERROR} from "../constants/values";
import { subscribeToTopic, logEvent } from '../firebase/FirebaseInit';
import * as Const from "../constants/values";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

//TODO DELETE THIS
export const userSignUp = (postData) => {
  return (dispatch) => {
    // dispatch({type: FETCH_START});
    // axios.post('account/register', postData
    // ).then(({data}) => {
    //   console.log(data);
    //     dispatch({type: FETCH_SUCCESS});
    //   if (data) {
    //     dispatch({type: SIGNUP_USER_SUCCESS, payload: data});
    //   }
    // }).catch(function (error) {
    //   dispatch({type: FETCH_ERROR, payload: error.message});
    //   console.log("Error****:", error.message);
    // });
  }
};

export const userSignIn = ({email, password}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    let brand = navigator.platform;
    let model = navigator.userAgent;
    let type = "web";
    var navigator_info = window.navigator;
    var screen_info = window.screen;
    var uid = navigator_info.mimeTypes.length;
    uid += navigator_info.userAgent.replace(/\D+/g, '');
    uid += navigator_info.plugins.length;
    uid += screen_info.height || '';
    uid += screen_info.width || '';
    uid += screen_info.pixelDepth || '';
    let deviceId = uid;

    axios.post('account/login', {
        email: email,
  	    password: password,
  	    langLoc:"el",
  	    brand: brand,
  	    model: model,
  	    type: type,
  	    deviceId: deviceId,
        fromAdmin: true
      }
    ).then(({data}) => {
      console.log("userSignIn: ", data);
      if (data.status){
        localStorage.setItem("token", JSON.stringify(data.token));
        localStorage.setItem("userId", JSON.stringify(data.userId));
        let topic = `${Const.ARG_FIREBASE_TOPIC_USER}${data.userId}`;
        subscribeToTopic(topic);
      axios.defaults.headers.common['Authorization'] = "Bearer " + data.token;
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_TOKEN_SET, payload: data.token});
      } else {
        dispatch({type: SHOW_MESSAGE, payload: data.message});
        dispatch({type: FETCH_ERROR, payload: data.message});
      }
    }).catch(function (error) {
      dispatch({type: SHOW_MESSAGE, payload: GENERAL_ERROR});
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const getUser = (userId) => {
  console.log("IGETUSER");
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('account/userinfo', {
        userId: userId,
  	    langLoc:"el",
        fromAdmin: true
      }
    ).then(({data}) => {
      console.log("userSignIn: ", data);
      if (data.userInfo){
        if (data.userInfo.userId === userId){ //STORE THE LOGGED IN USER DATA
          dispatch({type: FETCH_SUCCESS});
          localStorage.setItem("user", JSON.stringify(data.userInfo));
          dispatch({type: USER_DATA, payload: data.userInfo});

          var isAdmin = false;
          var isManager = false;
          if (data.userInfo.role == 1) {
            isAdmin = true;
          } 

          if (data.userInfo.role == 2) {
            isManager = true;
          } 

          dispatch({type: USER_IS_ADMIN, payload: isAdmin});
          dispatch({type: USER_IS_MANAGER, payload: isManager});

        }
      } else {
        dispatch({type: USER_DATA, payload: data});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};


export const userSignOut = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    //REMOVE THIS
    dispatch({type: FETCH_SUCCESS});
    localStorage.removeItem("token");
    dispatch({type: FETCH_SUCCESS});
    dispatch({type: SIGNOUT_USER_SUCCESS});
    //

    // axios.post('auth/logout',
    // ).then(({data}) => {
    //   if (data.result) {
    //     dispatch({type: FETCH_SUCCESS});
    //     localStorage.removeItem("token");
    //     dispatch({type: FETCH_SUCCESS});
    //     dispatch({type: SIGNOUT_USER_SUCCESS});
    //   } else {
    //     dispatch({type: FETCH_ERROR, payload: data.error});
    //   }
    // }).catch(function (error) {
    //   dispatch({type: FETCH_ERROR, payload: error.message});
    //   console.log("Error****:", error.message);
    // });
  }
};

export const resetPass = (postData) => {
  return (dispatch) => {
    axios.post('account/resetpass', postData).then(({data}) => {
      console.log(data);
      if (data){
        dispatch({type: RESET_PASS, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};
