// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu';
export const DRAWER_TYPE = 'drawer_type';
export const FIXED_DRAWER = 'fixed_drawer';
export const COLLAPSED_DRAWER = 'collapsible';
export const MINI_DRAWER = 'mini_drawer';
export const THEME_COLOR = 'theme_color';
export const DARK_THEME = 'dark_theme';
export const WINDOW_WIDTH = 'window-width';
export const SWITCH_LANGUAGE = 'switch-language';
export const CHANGE_DIRECTION = 'change-direction';

export const CHANGE_NAVIGATION_STYLE = 'change-navigation-style';
export const HORIZONTAL_NAVIGATION = 'horizontal_navigation';
export const VERTICAL_NAVIGATION = 'vertical_navigation';

export const HORIZONTAL_MENU_POSITION = 'horizontal_menu_position';
export const ABOVE_THE_HEADER = 'above_the_header';
export const INSIDE_THE_HEADER = 'inside_the_header';
export const BELOW_THE_HEADER = 'below_the_header';

//Contact Module const

export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_DATA = 'FETCH_DATA';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';

export const FETCH_ALL_CONTACT = 'fetch_all_contact';
export const FETCH_ALL_CONTACT_SUCCESS = 'fetch_all_contact_success';
export const ADD_FAVOURITE = 'add_favourite';
export const ON_CONTACT_SELECT = 'on_contact_select';
export const ON_ADD_CONTACT = 'on_add_contact';
export const ON_CONTACT_CLOSE = 'on_contact_close';
export const ON_FILTER_OPTION_SELECT = 'on_filter_option_select';
export const ON_SAVE_CONTACT = 'on_save_contact';
export const ON_DELETE_CONTACT = 'on_delete_contact';
export const ON_DELETE_SELECTED_CONTACT = 'on_delete_selected_contact';
export const FILTER_CONTACT = 'filter_contact';
export const GET_ALL_CONTACT = 'get_all_contact';
export const GET_UNSELECTED_ALL_CONTACT = 'get_unselected_all_contact';
export const ON_ALL_CONTACT_SELECT = 'on_all_contact_select';
export const UPDATE_SEARCH_USER = 'update_search_user';
export const ON_TOGGLE_DRAWER = 'on_toggle_drawer';
export const HANDLE_REQUEST_CLOSE = 'handle_request_close';
export const HIDE_CONTACT_LOADER = 'hide_contact_loader';

//Auth const
export const SIGNUP_USER = 'signup_user';
export const SIGNUP_USER_SUCCESS = 'signup_user_success';
export const SIGNIN_GOOGLE_USER = 'signin_google_user';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'signin_google_user_success';
export const SIGNIN_FACEBOOK_USER = 'signin_facebook_user';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'signin_facebook_user_success';
export const SIGNIN_TWITTER_USER = 'signin_twitter_user';
export const SIGNIN_TWITTER_USER_SUCCESS = 'signin_twitter_user_success';
export const SIGNIN_GITHUB_USER = 'signin_github_user';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_SUCCESS';
export const SIGNIN_USER = 'signin_user';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const INIT_URL = 'init_url';
export const USER_DATA = 'user_data';
export const USER_TOKEN_SET = 'user_token_set';

// Chat Module const

export const FETCH_ALL_CHAT_USER = 'fetch_all_chat_user';
export const FETCH_ALL_CHAT_USER_CONVERSATION = 'fetch_all_chat_user_conversation';
export const FETCH_ALL_CHAT_USER_SUCCESS = 'fetch_all_chat_user_success';
export const FETCH_ALL_CHAT_USER_CONVERSATION_SUCCESS = 'fetch_all_chat_user_conversation_success';
export const FILTER_USERS = 'filter_users';
export const ON_SELECT_USER = 'on_select_user';
export const ON_SHOW_LOADER = 'on_show_loader';
export const ON_HIDE_LOADER = 'on_hide_loader';
export const USER_INFO_STATE = 'user_info_state';
export const SUBMIT_COMMENT = 'submit_comment';
export const UPDATE_MESSAGE_VALUE = 'update_message_value';
export const UPDATE_SEARCH_CHAT_USER = 'update_search_chat_user';

//// Mail Module const


export const ADD_LABEL = 'ADD_LABEL';
export const GET_ALL_MAIL = 'get_all_mail';
export const FETCH_ALL_MAIL = 'fetch_all_mail';
export const FETCH_ALL_MAIL_SUCCESS = 'fetch_all_mail_success';
export const GET_IMPORTANT_MAIL = 'get_important_mail';
export const GET_NAV_FILTERS = 'get_nav_filters';
export const GET_NAV_LABELS = 'get_nav_labels';
export const GET_NAV_FOLDER = 'get_nav_folder';
export const GET_READ_MAIL = 'get_read_mail';
export const GET_STARRED_MAIL = 'get_starred_mail';
export const GET_UNIMPORTANT_MAIL = 'get_unimportant_mail';
export const GET_UNREAD_MAIL = 'get_unread_mail';
export const GET_UNSELECTED_ALL_MAIL = 'get_unselected_all_mail';
export const GET_UNSTARRED_MAIL = 'get_unstarred_mail';
export const ON_ALL_MAIL_SELECT = 'on_all_mail_select';
export const ON_FOLDER_MENU_ITEM_SELECT = 'on_folder_menu_item_select';
export const ON_FOLDER_SELECT = 'on_folder_select';
export const ON_IMPORTANT_SELECT = 'on_important_select';
export const ON_LABEL_MENU_ITEM_SELECT = 'on_label_menu_item_select';
export const GET_MARK_AS_START = 'get_mark_as_start';
export const ON_LABEL_SELECT = 'on_label_select';
export const ON_MAIL_CHECKED = 'on_mail_checked';
export const ON_MAIL_SELECT = 'on_mail_select';
export const ON_MAIL_SEND = 'on_mail_send';
export const ON_OPTION_MENU_ITEM_SELECT = 'on_option_menu_item_select';
export const ON_OPTION_MENU_SELECT = 'on_option_menu_select';
export const ON_START_SELECT = 'on_start_select';
export const SEARCH_MAIL = 'search_mail';
export const ON_DELETE_MAIL = 'on_delete_mail';
export const SET_CURRENT_MAIL_NULL = 'set_current_mail_null';
export const ON_COMPOSE_MAIL = 'on_compose_mail';

//// TO-DO Module const

export const ON_SORTEND = 'on_sortend';
export const FETCH_ALL_TODO = 'fetch_all_todo';
export const FETCH_ALL_TODO_SUCCESS = 'fetch_all_todo-success';
export const FETCH_ALL_TODO_CONVERSATION = 'fetch_all_todo_conversation';
export const FETCH_ALL_TODO_CONVERSATION_SUCCESS = 'fetch_all_todo_conversation_success';
export const SELECT_ALL_TODO = 'select_all_todo';
export const GET_ALL_TODO = 'get_all_todo';
export const GET_UNSELECTED_ALL_TODO = 'get_unselected_all_todo';
export const GET_STARRED_TODO = 'get_starred_todo';
export const GET_UNSTARRED_TODO = 'get_unstarred_todo';
export const GET_IMPORTANT_TODO = 'get_important_todo';
export const GET_UNIMPORTANT_TODO = 'get_unimportant_todo';
export const ON_LABEL_UPDATE = 'on_label_update';
export const ON_TODO_UPDATE = 'on_todo_update';
export const ON_DELETE_TODO = 'on_delete_todo';
export const SEARCH_TODO = 'search_todo';
export const SHOW_TODOS = 'show_todos';
export const GET_TODO_CONVERSATION = 'get_todo_conversation';
export const ON_TODO_CHECKED = 'on_todo_checked';
export const ON_TODO_ADD = 'on_todo_add';
export const ON_TODO_SELECT = 'on_todo_select';
export const SET_CURRENT_TODO_NULL = 'set_current_todo_null';
export const REMOVE_LABEL = 'remove_label';
export const UPDATE_SEARCH = 'update_search';

//USERS
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const FETCH_USERS = 'FETCH_USERS';
export const SINGLE_USER = 'SINGLE_USER';
export const EDIT_USER = 'EDIT_USER';
export const FORGOT_PASS = 'FORGOT_PASS';
export const RESET_PASS = 'RESET_PASS';
export const USER_IS_ADMIN = "USER_IS_ADMIN";
export const USER_IS_MANAGER = "USER_IS_MANAGER";


//ESTATES
export const FETCH_ESTATES_PENDING = 'FETCH_ESTATES_PENDING';
export const FETCH_ESTATES_SUCCESS = 'FETCH_ESTATES_SUCCESS';
export const FETCH_ESTATES_ERROR = 'FETCH_ESTATES_ERROR';
export const CREATE_ESTATE = 'CREATE_ESTATE';
export const EDIT_ESTATE = 'EDIT_ESTATE';
export const SINGLE_ESTATE = 'SINGLE_ESTATE';

//APARTMENTS
export const FETCH_APARTMENTS = 'FETCH_APARTMENTS';
export const CREATE_APARTMENT = 'CREATE_APARTMENT';
export const SINGLE_APARTMENT = 'SINGLE_APARTMENT';
export const EDIT_APARTMENT = 'EDIT_APARTMENT';
export const CREATE_APARTMENT_TIMER = 'CREATE_APARTMENT_TIMER';
export const DELETE_APARTMENT_TIMER = 'DELETE_APARTMENT_TIMER';
export const FETCH_APARTMENT_TIMERS = 'FETCH_APARTMENT_TIMERS';
export const DELETE_APARTMENT = 'DELETE_APARTMENT';


//ESTATE-OILS
export const FILL_ESTATE_OIL = 'FILL_ESTATE_OIL';
export const FETCH_ESTATE_OILS = 'FETCH_ESTATE_OILS';
export const DELETE_ESTATE_OIL = 'DELETE_ESTATE_OIL';

//LAMPS
export const ADD_NEW_LAMP = 'ADD_NEW_LAMP';
export const FETCH_ESTATE_LAMPS = 'FETCH_ESTATE_LAMPS';
export const EDIT_ESTATE_STATUS_LAMP = 'EDIT_ESTATE_STATUS_LAMP';
export const ADD_ESTATE_LAMP_CATEGORY = 'ADD_ESTATE_LAMP_CATEGORY';
export const FETCH_ESTATE_LAMPS_CATEGORIES = 'FETCH_ESTATE_LAMPS_CATEGORIES';
export const EDIT_ESTATE_LAMP_CATEGORY = "EDIT_ESTATE_LAMP_CATEGORY";
export const DELETE_ESTATE_LAMP_CATEGORY = "DELETE_ESTATE_LAMP_CATEGORY";

//KEYS
export const ADD_ESTATE_KEY = 'ADD_ESTATE_KEY';
export const EDIT_ESTATE_KEY = 'EDIT_ESTATE_KEY';
export const DELETE_ESTATE_KEY = 'DELETE_ESTATE_KEY';
export const FETCH_ESTATE_KEYS = 'FETCH_ESTATE_KEYS';
export const ADD_ESTATE_KEY_CATEGORY = 'ADD_ESTATE_KEY_CATEGORY';
export const FETCH_ESTATE_KEYS_CATEGORIES = 'FETCH_ESTATE_KEYS_CATEGORIES';
export const EDIT_ESTATE_KEY_CATEGORY = "EDIT_ESTATE_KEY_CATEGORY";
export const DELETE_ESTATE_KEY_CATEGORY = "DELETE_ESTATE_KEY_CATEGORY";

//DASHBOARD
export const FETCH_DASHBOARD = 'FETCH_DASHBOARD';
export const FETCH_DASHBOARD_SERVICES = "FETCH_DASHBOARD_SERVICES";

//VEHICLES
export const ADD_VEHICLE = 'ADD_VEHICLE';
export const EDIT_VEHICLE = 'EDIT_VEHICLE';
export const SINGLE_VEHICLE = 'SINGLE_VEHICLE';
export const FETCH_VEHICLES = 'FETCH_VEHICLES';

export const ADD_VEHICLE_WASH = 'ADD_VEHICLE_WASH';
export const EDIT_VEHICLE_WASH = 'EDIT_VEHICLE_WASH';
export const SINGLE_VEHICLE_WASH = 'SINGLE_VEHICLE_WASH';
export const FETCH_VEHICLES_WASH = 'FETCH_VEHICLES_WASH';
export const DELETE_VEHICLE_WASH = 'DELETE_VEHICLE_WASH';

export const ADD_VEHICLE_TIRES_CHECK = 'ADD_VEHICLE_TIRES_CHECK';
export const EDIT_VEHICLE_TIRES_CHECK = 'EDIT_VEHICLE_TIRES_CHECK';
export const SINGLE_VEHICLE_TIRES_CHECK = 'SINGLE_VEHICLE_TIRES_CHECK';
export const FETCH_VEHICLES_TIRES_CHECK = 'FETCH_VEHICLES_TIRES_CHECK';
export const DELETE_VEHICLE_TIRES_CHECK = 'DELETE_VEHICLE_TIRES_CHECK';

export const ADD_VEHICLE_SERVICE_CATEGORY = 'ADD_VEHICLE_SERVICE_CATEGORY';
export const EDIT_VEHICLE_SERVICE_CATEGORY = 'EDIT_VEHICLE_SERVICE_CATEGORY';
export const SINGLE_VEHICLE_SERVICE_CATEGORY = 'SINGLE_VEHICLE_SERVICE_CATEGORY';
export const FETCH_VEHICLES_SERVICE_CATEGORY = 'FETCH_VEHICLES_SERVICE_CATEGORY';
export const DELETE_VEHICLE_SERVICE_CATEGORY = 'DELETE_VEHICLE_SERVICE_CATEGORY';

export const ADD_VEHICLE_SERVICE = 'ADD_VEHICLE_SERVICE'; 
export const EDIT_VEHICLE_SERVICE = 'EDIT_VEHICLE_SERVICE';
export const SINGLE_VEHICLE_SERVICE = 'SINGLE_VEHICLE_SERVICE';
export const FETCH_VEHICLES_SERVICE = 'FETCH_VEHICLES_SERVICE';
export const DELETE_VEHICLE_SERVICE = 'DELETE_VEHICLE_SERVICE';

export const ADD_VEHICLE_OIL_CHECK = 'ADD_VEHICLE_OIL_CHECK';
export const EDIT_VEHICLE_OIL_CHECK= 'EDIT_VEHICLE_OIL_CHECK';
export const SINGLE_VEHICLE_OIL_CHECK = 'SINGLE_VEHICLE_OIL_CHECK';
export const FETCH_VEHICLES_OIL_CHECK = 'FETCH_VEHICLES_OIL_CHECK';
export const DELETE_VEHICLE_OIL_CHECK = 'DELETE_VEHICLE_OIL_CHECK';

export const ADD_VEHICLE_KTEO_CHECK = 'ADD_VEHICLE_KTEO_CHECK';
export const EDIT_VEHICLE_KTEO_CHECK= 'EDIT_VEHICLE_KTEO_CHECK';
export const SINGLE_VEHICLE_KTEO_CHECK = 'SINGLE_VEHICLE_KTEO_CHECK';
export const FETCH_VEHICLES_KTEO_CHECK = 'FETCH_VEHICLES_KTEO_CHECK';
export const DELETE_VEHICLE_KTEO_CHECK = 'DELETE_VEHICLE_KTEO_CHECK';

export const ADD_VEHICLE_INSURANCE_CHECK = 'ADD_VEHICLE_INSURANCE_CHECK';
export const EDIT_VEHICLE_INSURANCE_CHECK= 'EDIT_VEHICLE_INSURANCE_CHECK';
export const SINGLE_VEHICLE_INSURANCE_CHECK = 'SINGLE_VEHICLE_INSURANCE_CHECK';
export const FETCH_VEHICLES_INSURANCE_CHECK = 'FETCH_VEHICLES_INSURANCE_CHECK';
export const DELETE_VEHICLE_INSURANCE_CHECK = 'DELETE_VEHICLE_INSURANCE_CHECK';

export const ADD_VEHICLE_FUEL = 'ADD_VEHICLE_FUEL';
export const EDIT_VEHICLE_FUEL= 'EDIT_VEHICLE_FUEL';
export const SINGLE_VEHICLE_FUEL = 'SINGLE_VEHICLE_FUEL';
export const FETCH_VEHICLES_FUEL = 'FETCH_VEHICLES_FUEL';
export const DELETE_VEHICLE_FUEL = 'DELETE_VEHICLE_FUEL';

export const ADD_VEHICLE_EXHAUST_CONTROL_CARD = 'ADD_VEHICLE_EXHAUST_CONTROL_CARD';
export const EDIT_VEHICLE_EXHAUST_CONTROL_CARD= 'EDIT_VEHICLE_EXHAUST_CONTROL_CARD';
export const SINGLE_VEHICLE_EXHAUST_CONTROL_CARD = 'SINGLE_VEHICLE_EXHAUST_CONTROL_CARD';
export const FETCH_VEHICLES_EXHAUST_CONTROL_CARD = 'FETCH_VEHICLES_EXHAUST_CONTROL_CARD';
export const DELETE_VEHICLE_EXHAUST_CONTROL_CARD = 'DELETE_VEHICLE_EXHAUST_CONTROL_CARD';

export const ADD_VEHICLE_MOVEMENT = 'ADD_VEHICLE_MOVEMENT';
export const EDIT_VEHICLE_MOVEMENT= 'EDIT_VEHICLE_MOVEMENT';
export const SINGLE_VEHICLE_MOVEMENT = 'SINGLE_VEHICLE_MOVEMENT';
export const FETCH_VEHICLES_MOVEMENT = 'FETCH_VEHICLES_MOVEMENT';
export const DELETE_VEHICLE_MOVEMENT = 'DELETE_VEHICLE_MOVEMENT';


//ESTATE SERVICES
export const CREATE_ESTATE_SERVICE = 'CREATE_ESTATE_SERVICE';
export const EDIT_ESTATE_SERVICE= 'EDIT_ESTATE_SERVICE';
export const FETCH_ESTATE_SERVICES = 'FETCH_ESTATE_SERVICES';
export const SINGLE_ESTATE_SERVICE = 'SINGLE_ESTATE_SERVICE';
export const DELETE_ESTATE_SERVICE = 'DELETE_ESTATE_SERVICE';

export const ADD_ESTATE_SERVICE_TASK = 'ADD_ESTATE_SERVICE_TASK';
export const EDIT_ESTATE_SERVICE_TASK = 'EDIT_ESTATE_SERVICE_TASK';
export const FETCH_ESTATE_SERVICE_TASKS = 'FETCH_ESTATE_SERVICE_TASKS';
export const SINGLE_ESTATE_SERVICE_TASK = 'SINGLE_ESTATE_SERVICE_TASK';
export const DELETE_ESTATE_SERVICE_TASK = 'DELETE_ESTATE_SERVICE_TASK';
export const FETCH_ESTATE_SERVICES_VIEW = 'FETCH_ESTATE_SERVICES_VIEW';


//STORE SERVICES
export const ADD_STORE_CATEGORY = 'ADD_STORE_CATEGORY';
export const EDIT_STORE_CATEGORY = 'EDIT_STORE_CATEGORY';
export const SINGLE_STORE_CATEGORY = 'SINGLE_STORE_CATEGORY';
export const FETCH_STORE_CATEGORY = 'FETCH_STORE_CATEGORY';
export const DELETE_STORE_CATEGORY = 'DELETE_STORE_CATEGORY';

export const ADD_STORE_AUTH_HOLDER = 'ADD_STORE_AUTH_HOLDER';
export const EDIT_STORE_AUTH_HOLDER = 'EDIT_STORE_AUTH_HOLDER';
export const SINGLE_STORE_AUTH_HOLDER = 'SINGLE_STORE_AUTH_HOLDER';
export const FETCH_STORE_AUTH_HOLDER = 'FETCH_STORE_AUTH_HOLDER';
export const DELETE_STORE_AUTH_HOLDER = 'DELETE_STORE_AUTH_HOLDER';

export const ADD_STORE_SUPPLIER = 'ADD_STORE_SUPPLIER';
export const EDIT_STORE_SUPPLIER = 'EDIT_STORE_SUPPLIER';
export const SINGLE_STORE_SUPPLIER = 'SINGLE_STORE_SUPPLIER';
export const FETCH_STORE_SUPPLIER = 'FETCH_STORE_SUPPLIER';
export const DELETE_STORE_SUPPLIER = 'DELETE_STORE_SUPPLIER';

export const ADD_STORE_SELLER = 'ADD_STORE_SELLER';
export const EDIT_STORE_SELLER = 'EDIT_STORE_SELLER';
export const SINGLE_STORE_SELLER = 'SINGLE_STORE_SELLER';
export const FETCH_STORE_SELLER = 'FETCH_STORE_SELLER';
export const DELETE_STORE_SELLER = 'DELETE_STORE_SELLER';

export const ADD_STORE_PRODUCT = 'ADD_STORE_PRODUCT';
export const EDIT_STORE_PRODUCT = 'EDIT_STORE_PRODUCT';
export const SINGLE_STORE_PRODUCT = 'SINGLE_STORE_PRODUCT';
export const FETCH_STORE_PRODUCT = 'FETCH_STORE_PRODUCT';
export const DELETE_STORE_PRODUCT = 'DELETE_STORE_PRODUCT';

export const ADD_STORE_RECEIPT = 'ADD_STORE_RECEIPT';
export const EDIT_STORE_RECEIPT = 'EDIT_STORE_RECEIPT';
export const SINGLE_STORE_RECEIPT = 'SINGLE_STORE_RECEIPT';
export const FETCH_STORE_RECEIPT = 'FETCH_STORE_RECEIPT';
export const DELETE_STORE_RECEIPT = 'DELETE_STORE_RECEIPT';

export const ADD_STORE_USER_SUPPLY = 'ADD_STORE_USER_SUPPLY';
export const EDIT_STORE_USER_SUPPLY = 'EDIT_STORE_USER_SUPPLY';
export const SINGLE_STORE_USER_SUPPLY = 'SINGLE_STORE_USER_SUPPLY';
export const FETCH_STORE_USER_SUPPLY = 'FETCH_STORE_USER_SUPPLY';
export const DELETE_STORE_USER_SUPPLY = 'DELETE_STORE_USER_SUPPLY';

export const ADD_STORE_ORDER = 'ADD_STORE_ORDER';
export const EDIT_STORE_ORDER = 'EDIT_STORE_ORDER';
export const SINGLE_STORE_ORDER = 'SINGLE_STORE_ORDER';
export const FETCH_STORE_ORDER = 'FETCH_STORE_ORDER';
export const DELETE_STORE_ORDER = 'DELETE_STORE_ORDER';

//NOTIFICATIONS
export const ADD_PUSH_NOTIFICATION = 'ADD_PUSH_NOTIFICATION';
export const FETCH_PUSH_NOTIFICATIONS = 'FETCH_PUSH_NOTIFICATIONS';

//REMINDERS
export const ADD_REMINDER = 'ADD_REMINDER';
export const EDIT_REMINDER = 'EDIT_REMINDER';
export const SINGLE_REMINDER = 'SINGLE_REMINDER';
export const FETCH_REMINDERS = 'FETCH_REMINDERS';
export const DELETE_REMINDER = 'DELETE_REMINDER';


//BILLS
export const ADD_BILL_CHECK = 'ADD_BILL_CHECK';
export const ADD_BILL_CHECK_PAY = 'ADD_BILL_CHECK_PAY';
export const DELETE_BILL_CHECK = 'DELETE_BILL_CHECK';
export const DELETE_BILL_CHECK_PAY = 'DELETE_BILL_CHECK_PAY';


//NEW****
//AUTH
export const USER_LOCALE = 'USER_LOCALE';
export const USER_ID_SET = 'USER_ID_SET';

//SETTINGS
export const FETCH_SETTINGS = 'FETCH_SETTINGS';


//TRANSLATIONS
export const FETCH_ADMIN_TRANSLATIONS = 'FETCH_ADMIN_TRANSLATIONS';
export const FETCH_TRANSLATIONS = 'FETCH_TRANSLATIONS';
export const ADD_TRANSLATION = 'ADD_TRANSLATION';
export const EDIT_TRANSLATION = 'EDIT_TRANSLATION';
export const DELETE_TRANSLATION = 'DELETE_TRANSLATION';
export const STORED_TRANSLATIONS = 'STORED_TRANSLATIONS';


//CLIENTS
export const CREATE_CLIENT = 'CREATE_CLIENT';
export const EDIT_CLIENT = 'EDIT_CLIENT';
export const ALL_CLIENT = 'ALL_CLIENT';
export const SINGLE_CLIENT = 'SINGLE_CLIENT';

//CLIENT OFFERS
export const FETCH_CLIENT_OFFERS = 'FETCH_CLIENT_OFFERS';
export const CREATE_CLIENT_OFFER = 'CREATE_CLIENT_OFFER';
export const SINGLE_CLIENT_OFFER = 'SINGLE_CLIENT_OFFER';
export const EDIT_CLIENT_OFFER = 'EDIT_CLIENT_OFFER';
export const DELETE_CLIENT_OFFER = 'DELETE_CLIENT_OFFER';

//CLIENT_REMINDERS
export const FETCH_CLIENT_REMINDERS = 'FETCH_CLIENT_REMINDERS';
export const CREATE_CLIENT_REMINDER = 'CREATE_CLIENT_REMINDER';
export const SINGLE_CLIENT_REMINDER = 'SINGLE_CLIENT_REMINDER';
export const EDIT_CLIENT_REMINDER = 'EDIT_CLIENT_REMINDER';
export const DELETE_CLIENT_REMINDER = 'DELETE_CLIENT_REMINDER';

//CLIENT_NOTES
export const FETCH_CLIENT_NOTES = 'FETCH_CLIENT_NOTES';
export const CREATE_CLIENT_NOTE = 'CREATE_CLIENT_NOTE';
export const SINGLE_CLIENT_NOTE = 'SINGLE_CLIENT_NOTE';
export const EDIT_CLIENT_NOTE = 'EDIT_CLIENT_NOTE';
export const DELETE_CLIENT_NOTE = 'DELETE_CLIENT_NOTE';

//CLIENT_REMINDERS
export const FETCH_CLIENT_SERVICES = 'FETCH_CLIENT_SERVICES';
export const CREATE_CLIENT_SERVICE = 'CREATE_CLIENT_SERVICE';
export const SINGLE_CLIENT_SERVICE = 'SINGLE_CLIENT_SERVICE';
export const EDIT_CLIENT_SERVICE = 'EDIT_CLIENT_SERVICE';
export const DELETE_CLIENT_SERVICE = 'DELETE_CLIENT_SERVICE';


//UPLOAD
export const POST_UPLOAD_FILE = 'POST_UPLOAD_FILE';

//CLIENT SERVICE CATEGORIES
export const ADD_CLIENT_SERVICE_CATEGORY = 'ADD_ESTATE_KEY_CATEGORY';
export const FETCH_CLIENT_SERVICES_CATEGORIES = 'FETCH_CLIENT_SERVICES_CATEGORIES';
export const EDIT_CLIENT_SERVICE_CATEGORY = "EDIT_CLIENT_SERVICE_CATEGORY";
export const DELETE_CLIENT_SERVICE_CATEGORY = "DELETE_CLIENT_SERVICE_CATEGORY";

//report
export const REPORT_CLIENT_DATA = "REPORT_CLIENT_DATA";
export const REPORT_SERVICE_DATA = "REPORT_SERVICE_DATA";