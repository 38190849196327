import {
   ADD_STORE_CATEGORY,
   EDIT_STORE_CATEGORY,
   SINGLE_STORE_CATEGORY,
   FETCH_STORE_CATEGORY,
   DELETE_STORE_CATEGORY
  }
from '../constants/ActionTypes';

const INIT_STATE = {
  addStoreCategory: null,
  editStoreCategory: null,
  singleStoreCategory: null,
  storeCategoryData: null,
  deleteStoreCategory: null
};

export default (state = INIT_STATE, action) => {
    switch(action.type) {
      case ADD_STORE_CATEGORY:
               return {
                 ...state,
               addStoreCategory: action.payload
            }
      case EDIT_STORE_CATEGORY:
                 return {
                 ...state,
                 editStoreCategory: action.payload
          }

      case SINGLE_STORE_CATEGORY:
            return {
              ...state,
              singleStoreCategory: action.payload
        }
      case FETCH_STORE_CATEGORY:
               return {
                 ...state,
               storeCategoryData: action.payload
      }
      case DELETE_STORE_CATEGORY:
            return {
              ...state,
              deleteStoreCategory: action.payload
      }
    default:
          return state;
    }
}
