import {
   ADD_VEHICLE_KTEO_CHECK,
   EDIT_VEHICLE_KTEO_CHECK,
   SINGLE_VEHICLE_KTEO_CHECK,
   FETCH_VEHICLES_KTEO_CHECK,
   DELETE_VEHICLE_KTEO_CHECK
  }
from '../constants/ActionTypes';

const INIT_STATE = {
  addVehicleKteoCheck: null,
  editVehicleKteoCheck: null,
  singleVehicleKteoCheck: null,
  vehiclesKteoCheckData: null,
  deleteVehicleKteoCheck: null
};

export default (state = INIT_STATE, action) => {
    switch(action.type) {
      case ADD_VEHICLE_KTEO_CHECK:
               return {
                 ...state,
               addVehicleKteoCheck: action.payload
            }
      case EDIT_VEHICLE_KTEO_CHECK:
                 return {
                 ...state,
                 editVehicleKteoCheck: action.payload
          }

      case SINGLE_VEHICLE_KTEO_CHECK:
            return {
              ...state,
              singleVehicleKteoCheck: action.payload
        }
      case FETCH_VEHICLES_KTEO_CHECK:
               return {
                 ...state,
               vehiclesKteoCheckData: action.payload
      }
      case DELETE_VEHICLE_KTEO_CHECK:
            return {
              ...state,
              deleteVehicleKteoCheck: action.payload
      }
    default:
          return state;
    }
}
