import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SHOW_MESSAGE,
  ADD_VEHICLE_EXHAUST_CONTROL_CARD,
  EDIT_VEHICLE_EXHAUST_CONTROL_CARD,
  SINGLE_VEHICLE_EXHAUST_CONTROL_CARD,
  FETCH_VEHICLES_EXHAUST_CONTROL_CARD,
  DELETE_VEHICLE_EXHAUST_CONTROL_CARD
} from "../constants/ActionTypes";
import axios from 'util/Api'

import {GENERAL_ERROR} from "../constants/values";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const getVehiclesExhaustControlCard = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('vehicleExhaustControlCard/all', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: FETCH_VEHICLES_EXHAUST_CONTROL_CARD, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const addVehicleExhaustControlCard = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('vehicleExhaustControlCard/add', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: ADD_VEHICLE_EXHAUST_CONTROL_CARD, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const editVehicleExhaustControlCard = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('vehicleExhaustControlCard/edit', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: EDIT_VEHICLE_EXHAUST_CONTROL_CARD, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const singleVehicleExhaustControlCard = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('vehicleExhaustControlCard/single', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SINGLE_VEHICLE_EXHAUST_CONTROL_CARD, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const deleteVehicleExhaustControlCard = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('vehicleExhaustControlCard/delete', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: DELETE_VEHICLE_EXHAUST_CONTROL_CARD, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};
