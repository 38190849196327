import React from 'react';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';

const Footer = () => {
    return (
      <footer className="app-footer">
        <span className="d-inline-block">@{new Date().getFullYear()} FOCUS-ON TEAM – <a target="_blank" href="https://www.focus-on.gr/">POWERED BY FOCUS-ON GROUP</a> - {` Έκδοση: ${process.env.REACT_APP_VERSION}`}</span>
        {/* <Button style={{ borderRadius: 20 }}
          href="mailto:apps@focus-on.gr"
          target="_blank"
          size="small"
          color="primary"
        ><IntlMessages id="ΥΠΟΣΤΗΡΙΞΗ"/></Button> */}
      </footer>
    );
  }
;

export default Footer;
