import {
  ADD_ESTATE_LAMP_CATEGORY,
  EDIT_ESTATE_LAMP_CATEGORY,
  DELETE_ESTATE_LAMP_CATEGORY,
  FETCH_ESTATE_LAMPS_CATEGORIES,
  ADD_NEW_LAMP,
  FETCH_ESTATE_LAMPS,
  EDIT_ESTATE_STATUS_LAMP } from '../constants/ActionTypes';

const INIT_STATE = {
  addLampData: null,
  estateLampsData: null,
  editLampStatusData: null,
  addEstateLampCategory: null,
  editEstateLampCategory: null,
  deleteEstateLampCategory: null,
  estateLampsCategoriesData: null
};

export default (state = INIT_STATE, action) => {
  switch(action.type) {
   case ADD_NEW_LAMP:
       return {
           ...state,
           addLampData: action.payload
       }
   case FETCH_ESTATE_LAMPS:
        return {
            ...state,
            estateLampsData: action.payload
        }
  case EDIT_ESTATE_STATUS_LAMP:
           return {
             ...state,
           editLampStatusData: action.payload
        }
  case ADD_ESTATE_LAMP_CATEGORY:
            return {
                ...state,
            addEstateLampCategory: action.payload
        }
  case EDIT_ESTATE_LAMP_CATEGORY:
            return {
                ...state,
            editEstateLampCategory: action.payload
        }
   case DELETE_ESTATE_LAMP_CATEGORY:
             return {
              ...state,
              deleteEstateLampCategory: action.payload
       }
   case FETCH_ESTATE_LAMPS_CATEGORIES:
             return {
            ...state,
            estateLampsCategoriesData: action.payload
      }
  default:
        return state;
  }
}
