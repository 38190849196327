import {CREATE_USER_SUCCESS,FETCH_USERS,SINGLE_USER,EDIT_USER,FORGOT_PASS} from '../constants/ActionTypes';

const INIT_STATE = {
  createUser: null,
  usersData: null,
  singleUser: null,
  editUser: null,
  forgotPass: null
};

export default (state = INIT_STATE, action) => {
  switch(action.type) {
   case CREATE_USER_SUCCESS:
       return {
           ...state,
           createUser: action.payload
       }
   case FETCH_USERS:
   return {
        ...state,
        usersData: action.payload
      }
   case SINGLE_USER:
   return {
        ...state,
        singleUser: action.payload
   }

   case EDIT_USER:
   return {
        ...state,
        editUser: action.payload
   }
   case FORGOT_PASS:
    return {
        ...state,
        forgotPass: action.payload
    }

  default:
        return state;
  }
}
