import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  CREATE_CLIENT,
  EDIT_CLIENT,
  SINGLE_CLIENT,
  ALL_CLIENT
} from "../constants/ActionTypes";
import axios from 'util/Api'

import {GENERAL_ERROR} from "../constants/values";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const getClients = (userId, query, page, limit,sort,searchKeyword) => {
  let postData = {
      userId: userId,
      query: query,
      page: page,
      limit: limit,
      sort: sort,
      searchKeyword: searchKeyword
    }

    //console.log(postData);

  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('client/all', postData
    ).then(({data}) => {
      console.log("clientDATA",data);
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: ALL_CLIENT, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const createClient = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('client/create', postData
    ).then(({data}) => {
      console.log("clientDATA",data);
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: CREATE_CLIENT, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const editClient = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('client/edit', postData
    ).then(({data}) => {
      //console.log("clientDATA",data);
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: EDIT_CLIENT, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const singleClient = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('client/single', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SINGLE_CLIENT, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};
