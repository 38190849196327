import {
    FETCH_ADMIN_USERS,
      FETCH_ERROR,
      FETCH_START,
      POST_UPLOAD_FILE,
      INIT_URL,
      USER_ID_SET
    } from "../constants/ActionTypes";
    import axios from 'util/Api'
 
    export const setInitUrl = (url) => {
      return {
        type: INIT_URL,
        payload: url
      };
    };
  
    export const postUploadImageFile = (userId,type,files) => {

        //Create a form data object;
      var formData = new FormData();
      formData.append("userId", userId);
      formData.append("type", type);
    
      //Loop through the files to add them in the form data
      for (var i = 0; i < files.length; i++) {
        var file = files[i];
        formData.append('file', file);
      }
  
      return (dispatch) => {
        dispatch({type: FETCH_START});
        axios.post('upload/upload-file', formData
        ).then(({data}) => {
          // console.log(data);
          if (data){
            dispatch({type: POST_UPLOAD_FILE, payload: data});
          } 
        }).catch(function (error) {
          dispatch({type: FETCH_ERROR, payload: error.message});
          console.log("Error****:", error.message);
        });
      }
    };
  
   