import React from 'react';
import CustomScrollbars from 'util/CustomScrollbars';
import Navigation from "../../components/Navigation";

const SideBarContent = () => {
  const navigationMenus = [
    {
      name: 'ΑΡΧΙΚΗ',
      type: 'item',
      icon: 'view-dashboard',
      link:'/app/dashboard'
    },
    {
      name: 'ΧΡΗΣΤΕΣ',
      type: 'item',
      icon: 'accounts-alt',
      link:'/app/users'
    },
    {
      name: 'ΠΕΛΑΤΕΣ',
      type: 'item',
      icon: 'store',
      link:'/app/clients'
    },
    {
      name: 'ΕΠΑΦΕΣ',
      type: 'item',
      icon: 'pin-account',
      link:'/app/contacts'
    },
    {
      name: 'ΕΙΔΟΠΟΙΗΣΕΙΣ',
      type: 'item',
      icon: 'notifications',
      link:'/app/notifications'
  },
  {
      name: 'ΥΠΕΝΘΥΜΙΣΕΙΣ',
      type: 'item',
      icon: 'calendar-note',
      link:'/app/reminders'
  },
  {
      name: 'ΚΑΤΗΓ. ΥΠΗΡΕΣΙΩΝ',
      type: 'item',
      icon: 'key',
      link:'/app/clientservices/categories'
  },
  {
    name: 'ΑΝΑΦΟΡΕΣ',
    type: 'item',
    icon: 'book',
    link:'/app/reports'
},
  {
    name: 'ΜΕΤΑΦΡΑΣΕΙΣ',
    type: 'item',
    icon: 'translate',
    link:'/app/translations'
  }
  ];

  return (
    <CustomScrollbars className=" scrollbar">
      <Navigation menuItems={navigationMenus}/>
    </CustomScrollbars>
  );
};

export default SideBarContent;
