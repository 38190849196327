import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SHOW_MESSAGE,
  ADD_VEHICLE_INSURANCE_CHECK,
  EDIT_VEHICLE_INSURANCE_CHECK,
  SINGLE_VEHICLE_INSURANCE_CHECK,
  FETCH_VEHICLES_INSURANCE_CHECK,
  DELETE_VEHICLE_INSURANCE_CHECK
} from "../constants/ActionTypes";
import axios from 'util/Api'

import {GENERAL_ERROR} from "../constants/values";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const getVehiclesInsuranceCheck = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('vehicleInsuranceCheck/all', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: FETCH_VEHICLES_INSURANCE_CHECK, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const addVehicleInsuranceCheck = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('vehicleInsuranceCheck/add', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: ADD_VEHICLE_INSURANCE_CHECK, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const editVehicleInsuranceCheck = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('vehicleInsuranceCheck/edit', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: EDIT_VEHICLE_INSURANCE_CHECK, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const singleVehicleInsuranceCheck = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('vehicleInsuranceCheck/single', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SINGLE_VEHICLE_INSURANCE_CHECK, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const deleteVehicleInsuranceCheck = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('vehicleInsuranceCheck/delete', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: DELETE_VEHICLE_INSURANCE_CHECK, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};
