import {
   ADD_VEHICLE_SERVICE,
   EDIT_VEHICLE_SERVICE,
   SINGLE_VEHICLE_SERVICE,
   FETCH_VEHICLES_SERVICE,
   DELETE_VEHICLE_SERVICE
  }
from '../constants/ActionTypes';

const INIT_STATE = {
  addVehicleService: null,
  editVehicleService: null,
  singleVehicleService: null,
  vehiclesServiceData: null,
  deleteVehicleService: null
};

export default (state = INIT_STATE, action) => {
    switch(action.type) {
      case ADD_VEHICLE_SERVICE:
               return {
                 ...state,
               addVehicleService: action.payload
            }
      case EDIT_VEHICLE_SERVICE:
                 return {
                 ...state,
                 editVehicleService: action.payload
          }

      case SINGLE_VEHICLE_SERVICE:
            return {
              ...state,
              singleVehicleService: action.payload
        }
      case FETCH_VEHICLES_SERVICE:
               return {
                 ...state,
               vehiclesServiceData: action.payload
      }
      case DELETE_VEHICLE_SERVICE:
            return {
              ...state,
              deleteVehicleService: action.payload
      }
    default:
          return state;
    }
}
