import React, {useEffect,useState} from "react";
import {createMuiTheme} from "@material-ui/core/styles";
import {ThemeProvider} from "@material-ui/styles";
import URLSearchParams from "url-search-params";
import MomentUtils from "@date-io/moment";
import {MuiPickersUtilsProvider} from "material-ui-pickers";
import {Redirect, Route, Switch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {IntlProvider} from "react-intl";
import "assets/vendors/style";
import indigoTheme from "./themes/indigoTheme";
import cyanTheme from "./themes/cyanTheme";
import orangeTheme from "./themes/orangeTheme";
import amberTheme from "./themes/amberTheme";
import pinkTheme from "./themes/pinkTheme";
import blueTheme from "./themes/blueTheme";
import purpleTheme from "./themes/purpleTheme";
import greenTheme from "./themes/greenTheme";
import redTheme from "./themes/redTheme";
import AppLocale from "../lngProvider";
import {
  AMBER,
  BLUE,
  CYAN,
  DARK_AMBER,
  DARK_BLUE,
  DARK_CYAN,
  DARK_DEEP_ORANGE,
  DARK_DEEP_PURPLE,
  DARK_GREEN,
  DARK_INDIGO,
  DARK_PINK,
  DEEP_ORANGE,
  DEEP_PURPLE,
  GREEN,
  INDIGO,
  PINK,
  RED
} from "constants/ThemeColors";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import ChangePass from "./ChangePass";
import {setInitUrl} from "../actions/Auth";
import RTL from "util/RTL";
import {getSettings, setDarkTheme, setThemeColor} from "../actions/Setting";
import AppLayout from "./AppLayout";
import axios from 'util/Api'
import { askForPermissioToReceiveNotifications } from '../firebase/FirebaseInit';
import CircularProgress from "@material-ui/core/CircularProgress";
import Widget from "components/Widget/index";
import { Container } from "@material-ui/core";
import { getTranslations } from "actions";

const RestrictedRoute = ({component: Component, token, ...rest}) =>
  <Route
    {...rest}
    render={props =>
      token
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/signin',
            state: {from: props.location}
          }}
        />}
  />;

const App = (props) => {
  const dispatch = useDispatch();
  const {themeColor, darkTheme, locale, isDirectionRTL} = useSelector(({settings}) => settings);
  const {token, initURL} = useSelector(({auth}) => auth);

 

  const isDarkTheme = darkTheme;
  const {match, location} = props;

  const translationsData = useSelector(({translation}) => translation.translationsData);
  const [translationsLoaded,setTranslationsLoaded] = useState(false);

  const settingsData = useSelector(({settings}) => settings.settingsData);
  const [settingsLoaded,setSettingsLoaded] = useState(false)


  useEffect(() => {
    setTranslationsLoaded(false);
    setSettingsLoaded(false);
    // setUserDataLoaded(false);
    dispatch(getSettings()); 
    }, []);


    useEffect(() => {
      if (translationsData !== null) {
          setTranslationsLoaded(true);
          //WE GOT TRANSLATIONS. NOW GET USER INFO
      } 
    }, [translationsData]);
  
    useEffect(() => {
      if (settingsData !== null) {
        setSettingsLoaded(true); 
        dispatch(getTranslations()); 
      } 
    }, [settingsData]);

  useEffect(() => {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (initURL === '') {
      dispatch(setInitUrl(props.history.location.pathname));
    }
    const params = new URLSearchParams(props.location.search);
    if (params.has("theme-name")) {
      dispatch(setThemeColor(params.get('theme-name')));
    }
    if (params.has("dark-theme")) {
      dispatch(setDarkTheme());
    }

      askForPermissioToReceiveNotifications();

  }, [dispatch, initURL, props.history.location.pathname, props.location.search]);


  const getColorTheme = (themeColor, applyTheme) => {
    switch (themeColor) {
      case INDIGO: {
        applyTheme = createMuiTheme(indigoTheme);
        break;
      }
      case CYAN: {
        applyTheme = createMuiTheme(cyanTheme);
        break;
      }
      case AMBER: {
        applyTheme = createMuiTheme(amberTheme);
        break;
      }
      case DEEP_ORANGE: {
        applyTheme = createMuiTheme(orangeTheme);
        break;
      }
      case PINK: {
        applyTheme = createMuiTheme(pinkTheme);
        break;
      }
      case BLUE: {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
      case DEEP_PURPLE: {
        applyTheme = createMuiTheme(purpleTheme);
        break;
      }
      case GREEN: {
        applyTheme = createMuiTheme(greenTheme);
        break;
      }
      case DARK_INDIGO: {
        applyTheme = createMuiTheme({...indigoTheme, direction: 'rtl'});
        break;
      }
      case DARK_CYAN: {
        applyTheme = createMuiTheme(cyanTheme);
        break;
      }
      case DARK_AMBER: {
        applyTheme = createMuiTheme(amberTheme);
        break;
      }
      case DARK_DEEP_ORANGE: {
        applyTheme = createMuiTheme(orangeTheme);
        break;
      }
      case DARK_PINK: {
        applyTheme = createMuiTheme(pinkTheme);
        break;
      }
      case DARK_BLUE: {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
      case DARK_DEEP_PURPLE: {
        applyTheme = createMuiTheme(purpleTheme);
        break;
      }
      case DARK_GREEN: {
        applyTheme = createMuiTheme(greenTheme);
        break;
      }
      case RED: {
        applyTheme = createMuiTheme(redTheme);
        break;
      }
      default :
        createMuiTheme(indigoTheme);
    }
    return applyTheme;
  };

  let applyTheme = createMuiTheme(indigoTheme);
  if (isDarkTheme) {
    document.body.classList.add('dark-theme');
    applyTheme = createMuiTheme(darkTheme)
  } else {
    applyTheme = getColorTheme(themeColor, applyTheme);
  }

  //SET THE TOKEN BEARER TO BE AVAILALBLE
  if (token !== null){
    axios.defaults.headers.common['Authorization'] = "Bearer " + token;
  }

  if (location.pathname === '/') {
    if (token === null) {
      return (<Redirect to={'/signin'}/>);
    } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
      return (<Redirect to={'/app/dashboard'}/>);
    } else {
      return (<Redirect to={initURL}/>);
    }
  }
  if (isDirectionRTL) {
    applyTheme.direction = 'rtl';
    document.body.classList.add('rtl')
  } else {
    document.body.classList.remove('rtl');
    applyTheme.direction = 'ltr';
  }

  const currentAppLocale = AppLocale[locale.locale];
  console.log("in indexf file")

  if (!translationsLoaded || !settingsLoaded) {
    return (<Container className="app-wrapper">
  <div style={{height:'100%', width:'100%'}} >
    <div className=" justify-content-center">
      <CircularProgress/>
    </div>
  </div>

  </Container>
  );
  } else {
    return (
      <ThemeProvider theme={applyTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>
            <RTL>
              <div className="app-main">
                <Switch>
                  <RestrictedRoute path={`${match.url}app`} token={token}
                                   component={AppLayout}/>
                  <Route path='/signin' component={SignIn}/>
                  <Route path='/signup' component={SignUp}/>
                  <Route path='/change-password/:resetPassToken' component={ChangePass}/>
                  {/*<Route*/}
                  {/*  component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>*/}
                </Switch>
              </div>
            </RTL>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    );
  }


};


export default App;
