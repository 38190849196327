import {FETCH_CLIENT_NOTES,
  CREATE_CLIENT_NOTE,
  EDIT_CLIENT_NOTE,
  SINGLE_CLIENT_NOTE,
  DELETE_CLIENT_NOTE} from '../constants/ActionTypes';

const INIT_STATE = {
  clientNotes: null,
  createClientNote: null,
  editClientNote: null,
  singleClientNote: null,
  deleteClientNote: null
};

export default (state = INIT_STATE, action) => {
  switch(action.type) {
   case FETCH_CLIENT_NOTES:
       return {
           ...state,
           clientNotes: action.payload
       }
   case CREATE_CLIENT_NOTE:
        return {
            ...state,
            createClientNote: action.payload
        }
   case EDIT_CLIENT_NOTE:
         return {
             ...state,
             editClientNote: action.payload
         }
  case SINGLE_CLIENT_NOTE:
         return {
             ...state,
             singleClientNote: action.payload
         }
 case DELETE_CLIENT_NOTE:
          return {
            ...state,
            deleteClientNote: action.payload
          }
  default:
        return state;
  }
}
