import {
   ADD_VEHICLE_FUEL,
   EDIT_VEHICLE_FUEL,
   SINGLE_VEHICLE_FUEL,
   FETCH_VEHICLES_FUEL,
   DELETE_VEHICLE_FUEL
  }
from '../constants/ActionTypes';

const INIT_STATE = {
  addVehicleFuel: null,
  editVehicleFuel: null,
  singleVehicleFuel: null,
  vehiclesFuelData: null,
  deleteVehicleFuel: null
};

export default (state = INIT_STATE, action) => {
    switch(action.type) {
      case ADD_VEHICLE_FUEL:
               return {
                 ...state,
               addVehicleFuel: action.payload
            }
      case EDIT_VEHICLE_FUEL:
                 return {
                 ...state,
                 editVehicleFuel: action.payload
          }

      case SINGLE_VEHICLE_FUEL:
            return {
              ...state,
              singleVehicleFuel: action.payload
        }
      case FETCH_VEHICLES_FUEL:
               return {
                 ...state,
               vehiclesFuelData: action.payload
      }
      case DELETE_VEHICLE_FUEL:
            return {
              ...state,
              deleteVehicleFuel: action.payload
      }
    default:
          return state;
    }
}
