import {
   ADD_STORE_RECEIPT,
   EDIT_STORE_RECEIPT,
   SINGLE_STORE_RECEIPT,
   FETCH_STORE_RECEIPT,
   DELETE_STORE_RECEIPT
  }
from '../constants/ActionTypes';

const INIT_STATE = {
  addStoreReceipt: null,
  editStoreReceipt: null,
  singleStoreReceipt: null,
  storeReceiptData: null,
  deleteStoreReceipt: null
};

export default (state = INIT_STATE, action) => {
    switch(action.type) {
      case ADD_STORE_RECEIPT:
               return {
                 ...state,
               addStoreReceipt: action.payload
            }
      case EDIT_STORE_RECEIPT:
                 return {
                 ...state,
                 editStoreReceipt: action.payload
          }

      case SINGLE_STORE_RECEIPT:
            return {
              ...state,
              singleStoreReceipt: action.payload
        }
      case FETCH_STORE_RECEIPT:
               return {
                 ...state,
               storeReceiptData: action.payload
      }
      case DELETE_STORE_RECEIPT:
            return {
              ...state,
              deleteStoreReceipt: action.payload
      }
    default:
          return state;
    }
}
