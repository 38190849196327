import {FETCH_CLIENT_SERVICES,
  CREATE_CLIENT_SERVICE,
  EDIT_CLIENT_SERVICE,
  SINGLE_CLIENT_SERVICE,
  DELETE_CLIENT_SERVICE} from '../constants/ActionTypes';

const INIT_STATE = {
  clientServices: null,
  createClientService: null,
  editClientService: null,
  singleClientService: null,
  deleteClientService: null
};

export default (state = INIT_STATE, action) => {
  switch(action.type) {
   case FETCH_CLIENT_SERVICES:
       return {
           ...state,
           clientServices: action.payload
       }
   case CREATE_CLIENT_SERVICE:
        return {
            ...state,
            createClientService: action.payload
        }
   case EDIT_CLIENT_SERVICE:
         return {
             ...state,
             editClientService: action.payload
         }
  case SINGLE_CLIENT_SERVICE:
         return {
             ...state,
             singleClientService: action.payload
         }
 case DELETE_CLIENT_SERVICE:
          return {
            ...state,
            deleteClientService: action.payload
          }
  default:
        return state;
  }
}
