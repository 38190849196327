import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SHOW_MESSAGE,
  FETCH_PUSH_NOTIFICATIONS,
  ADD_PUSH_NOTIFICATION
} from "../constants/ActionTypes";
import axios from 'util/Api'

import {GENERAL_ERROR} from "../constants/values";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const getPushNotifications = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('admin/notifications', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: FETCH_PUSH_NOTIFICATIONS, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const addPushNotification = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('admin/sendpushtosome', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: ADD_PUSH_NOTIFICATION, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};
