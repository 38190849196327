import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SHOW_MESSAGE,
  ADD_VEHICLE_WASH,
  EDIT_VEHICLE_WASH,
  SINGLE_VEHICLE_WASH,
  FETCH_VEHICLES_WASH,
  DELETE_VEHICLE_WASH
} from "../constants/ActionTypes";
import axios from 'util/Api'

import {GENERAL_ERROR} from "../constants/values";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const getVehiclesWash = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('vehicleWash/all', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: FETCH_VEHICLES_WASH, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const addVehicleWash = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('vehicleWash/add', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: ADD_VEHICLE_WASH, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const editVehicleWash = (postData) => {
  console.log(postData);
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('vehicleWash/edit', postData
    ).then(({data}) => {
      console.log(data);
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: EDIT_VEHICLE_WASH, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const singleVehicleWash = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('vehicleWash/single', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SINGLE_VEHICLE_WASH, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const deleteVehicleWash = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('vehicleWash/delete', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: DELETE_VEHICLE_WASH, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};
