import {
   ADD_VEHICLE_EXHAUST_CONTROL_CARD,
   EDIT_VEHICLE_EXHAUST_CONTROL_CARD,
   SINGLE_VEHICLE_EXHAUST_CONTROL_CARD,
   FETCH_VEHICLES_EXHAUST_CONTROL_CARD,
   DELETE_VEHICLE_EXHAUST_CONTROL_CARD
  }
from '../constants/ActionTypes';

const INIT_STATE = {
  addVehicleExhaustControlCard: null,
  editVehicleExhaustControlCard: null,
  singleVehicleExhaustControlCard: null,
  vehiclesExhaustControlCardData: null,
  deleteVehicleExhaustControlCard: null
};

export default (state = INIT_STATE, action) => {
    switch(action.type) {
      case ADD_VEHICLE_EXHAUST_CONTROL_CARD:
               return {
                 ...state,
               addVehicleExhaustControlCard: action.payload
            }
      case EDIT_VEHICLE_EXHAUST_CONTROL_CARD:
                 return {
                 ...state,
                 editVehicleExhaustControlCard: action.payload
          }

      case SINGLE_VEHICLE_EXHAUST_CONTROL_CARD:
            return {
              ...state,
              singleVehicleExhaustControlCard: action.payload
        }
      case FETCH_VEHICLES_EXHAUST_CONTROL_CARD:
               return {
                 ...state,
               vehiclesExhaustControlCardData: action.payload
      }
      case DELETE_VEHICLE_EXHAUST_CONTROL_CARD:
            return {
              ...state,
              deleteVehicleExhaustControlCard: action.payload
      }
    default:
          return state;
    }
}
