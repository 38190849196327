import {
    FETCH_DASHBOARD,
    FETCH_DASHBOARD_SERVICES
  }
from '../constants/ActionTypes';

const INIT_STATE = {
  dashboardData: null,
  dashboardServicesData: null
};

export default (state = INIT_STATE, action) => {
  switch(action.type) {
  case FETCH_DASHBOARD:
             return {
             ...state,
             dashboardData: action.payload
      }
      case FETCH_DASHBOARD_SERVICES: 
      return {
        ...state,
        dashboardServicesData: action.payload
      }
  default:
        return state;
  }
}
