import {
   CREATE_ESTATE_SERVICE,
   EDIT_ESTATE_SERVICE,
   SINGLE_ESTATE_SERVICE,
   FETCH_ESTATE_SERVICES,
   DELETE_ESTATE_SERVICE,
   FETCH_ESTATE_SERVICES_VIEW
  }
from '../constants/ActionTypes';

const INIT_STATE = {
  addEstateService: null,
  editEstateService: null,
  singleEstateService: null,
  estateServicesData: null,
  deleteEstateService: null,
  estateServicesViewData: null
};

export default (state = INIT_STATE, action) => {
    switch(action.type) {
      case CREATE_ESTATE_SERVICE:
               return {
                 ...state,
               addEstateService: action.payload
            }
      case EDIT_ESTATE_SERVICE:
                 return {
                 ...state,
                 editEstateService: action.payload
          }

      case SINGLE_ESTATE_SERVICE:
            return {
              ...state,
              singleEstateService: action.payload
        }
      case FETCH_ESTATE_SERVICES:
               return {
                 ...state,
               estateServicesData: action.payload
      }
      case DELETE_ESTATE_SERVICE:
            return {
              ...state,
              deleteEstateService: action.payload
      }
      case FETCH_ESTATE_SERVICES_VIEW:
          return {
            ...state,
            estateServicesViewData: action.payload
          }
    default:
          return state;
    }
}
