import {
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
RESET_PASS,
USER_IS_ADMIN,
USER_IS_MANAGER
} from "../constants/ActionTypes";

const INIT_STATE = {
  token: JSON.parse(localStorage.getItem('token')),
  initURL: '',
  authUser: JSON.parse(localStorage.getItem('user')),
  resetPass: null,
  userIsAdmin: false,
  userIsManager: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_URL: {
        return {...state, initURL: action.payload};
      }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        token: null,
        authUser: null,
        initURL: ''
      }
    }
    case USER_DATA: {
      return {
        ...state,
        authUser: action.payload,
      };
    }
    case USER_TOKEN_SET: {
      return {
        ...state,
        token: action.payload,
      };
    }
    case RESET_PASS: {
      return {
        ...state,
        resetPass: action.payload,
      };
    }
    case USER_IS_ADMIN: 
      return {
      ...state,
      userIsAdmin: action.payload
      }
    case USER_IS_MANAGER: 
      return {
      ...state,
      userIsManager: action.payload
    }
    default:
      return state;
  }
}
