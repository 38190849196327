import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import asyncComponent from '../../util/asyncComponent';

const Routes = ({match}) =>
  <Switch>
   
    {/* <Route path={`${match.url}/estates`}
            component={asyncComponent(() => import('./Estates'))}/>
   
    <Route path={`${match.url}/vehicles`}
            component={asyncComponent(() => import('./Vehicles'))}/>
    <Route path={`${match.url}/store`}
            component={asyncComponent(() => import('./Store'))}/>
 
    <Route path={`${match.url}/reminders`}
            component={asyncComponent(() => import('./Reminders'))}/>
    <Route path={`${match.url}/keys/categories`}
            component={asyncComponent(() => import('./Estates/routes/Single/EstateKeys/EstateKeysCategories'))}/>
    <Route path={`${match.url}/lamps/categories`}
            component={asyncComponent(() => import('./Estates/routes/Single/EstateLamps/EstateLampsCategories'))}/>
    <Route path={`${match.url}/vehicle-service/categories`}
            component={asyncComponent(() => import('./Vehicles/routes/Single/VehicleService/VehicleServiceCategories'))}/>
    <Route path={`${match.url}/service-tasks`}
            component={asyncComponent(() => import('./Estates/routes/Single/EstateTasks'))}/> */}
    
    {/* NEW ADDED */}
    <Route path={`${match.url}/clientservices/categories`}
           component={asyncComponent(() => import('./Clients/Single/ClientServices/ClientServiceCategories'))}/>  
    <Route path={`${match.url}/dashboard`}
           component={asyncComponent(() => import('./Dashboard'))}/>
    <Route path={`${match.url}/users`}
            component={asyncComponent(() => import('./Users'))}/>
    <Route path={`${match.url}/notifications`}
            component={asyncComponent(() => import('./Notifications'))}/>
    <Route path={`${match.url}/reminders`}
            component={asyncComponent(() => import('./Reminders'))}/>        
    <Route path={`${match.url}/translations`}
           component={asyncComponent(() => import('./Translations'))}/>
    <Route path={`${match.url}/clients`}
            component={asyncComponent(() => import('./Clients'))}/>     
    <Route path={`${match.url}/contacts`}
            component={asyncComponent(() => import('./Clients'))}/>   
    <Route path={`${match.url}/reports`}
            component={asyncComponent(() => import('./Reports'))}/>   



    {/*<Route component={asyncComponent(() => import("app/routes/extraPages/routes/404"))}/>*/}

  </Switch>;


export default withRouter(Routes);
