import {
   ADD_CLIENT_SERVICE_CATEGORY,
   EDIT_CLIENT_SERVICE_CATEGORY,
   DELETE_CLIENT_SERVICE_CATEGORY,
   FETCH_CLIENT_SERVICES_CATEGORIES,
  }
from '../constants/ActionTypes';

const INIT_STATE = {
  addClientServiceCategory: null,
  editClientServiceCategory: null,
  deleteClientServiceCategory: null,
 clientServiceCategoriesData: null,
};

export default (state = INIT_STATE, action) => {
  switch(action.type) {
   case ADD_CLIENT_SERVICE_CATEGORY:
       return {
           ...state,
           addClientServiceCategory: action.payload
       }
   case EDIT_CLIENT_SERVICE_CATEGORY:
       return {
           ...state,
           editClientServiceCategory: action.payload
       }
  case DELETE_CLIENT_SERVICE_CATEGORY:
        return {
             ...state,
             deleteClientServiceCategory: action.payload
      }
   case FETCH_CLIENT_SERVICES_CATEGORIES:
        return {
            ...state,
            clientServiceCategoriesData: action.payload
        }
  default:
        return state;
  }
}
