import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SHOW_MESSAGE,
  FETCH_DATA,
  FETCH_CLIENT_OFFERS,
  CREATE_CLIENT_OFFER,
  EDIT_CLIENT_OFFER,
  DELETE_CLIENT_OFFER,
  SINGLE_CLIENT_OFFER,
} from "../constants/ActionTypes";
import axios from 'util/Api'

import {GENERAL_ERROR} from "../constants/values";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const getClientOffers = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('clientoffer/all', postData
    ).then(({data}) => {
      console.log("CLIENT_OFFER_DATA:",data);
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: FETCH_CLIENT_OFFERS, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const createClientOffer = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('clientoffer/create', postData
    ).then(({data}) => {
      console.log("CLIENT_OFFER_DATA",data);
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: CREATE_CLIENT_OFFER, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const editClientOffer = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('clientoffer/edit', postData
    ).then(({data}) => {
      console.log("CLIENT_OFFER_DATA",data);
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: EDIT_CLIENT_OFFER, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const singleClientOffer = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('clientoffer/single', postData
    ).then(({data}) => {
      console.log("CLIENT_OFFER_DATA",data);
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SINGLE_CLIENT_OFFER, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const deleteClientOffer = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('clientoffer/delete', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: DELETE_CLIENT_OFFER, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

