import {
   ADD_VEHICLE_SERVICE_CATEGORY,
   EDIT_VEHICLE_SERVICE_CATEGORY,
   SINGLE_VEHICLE_SERVICE_CATEGORY,
   FETCH_VEHICLES_SERVICE_CATEGORY,
   DELETE_VEHICLE_SERVICE_CATEGORY
  }
from '../constants/ActionTypes';

const INIT_STATE = {
  addVehicleServiceCategory: null,
  editVehicleServiceCategory: null,
  singleVehicleServiceCategory: null,
  vehiclesServiceCategoryData: null,
  deleteVehicleServiceCategory: null
};

export default (state = INIT_STATE, action) => {
    switch(action.type) {
      case ADD_VEHICLE_SERVICE_CATEGORY:
               return {
                 ...state,
               addVehicleServiceCategory: action.payload
            }
      case EDIT_VEHICLE_SERVICE_CATEGORY:
                 return {
                 ...state,
                 editVehicleServiceCategory: action.payload
          }

      case SINGLE_VEHICLE_SERVICE_CATEGORY:
            return {
              ...state,
              singleVehicleServiceCategory: action.payload
        }
      case FETCH_VEHICLES_SERVICE_CATEGORY:
               return {
                 ...state,
               vehiclesServiceCategoryData: action.payload
      }
      case DELETE_VEHICLE_SERVICE_CATEGORY:
            return {
              ...state,
              deleteVehicleServiceCategory: action.payload
      }
    default:
          return state;
    }
}
