import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SHOW_MESSAGE,
  FETCH_DATA,
  FETCH_CLIENT_REMINDERS,
  CREATE_CLIENT_REMINDER,
  EDIT_CLIENT_REMINDER,
  DELETE_CLIENT_REMINDER,
  SINGLE_CLIENT_REMINDER,
} from "../constants/ActionTypes";
import axios from 'util/Api'

import {GENERAL_ERROR} from "../constants/values";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const getClientReminders = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('clientreminder/all', postData
    ).then(({data}) => {
      console.log("CLIENT_REMINDER_DATA:",data);
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: FETCH_CLIENT_REMINDERS, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const createClientReminder = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('clientreminder/create', postData
    ).then(({data}) => {
      console.log("CLIENT_REMINDER_DATA",data);
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: CREATE_CLIENT_REMINDER, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const editClientReminder = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('clientreminder/edit', postData
    ).then(({data}) => {
      console.log("CLIENT_REMINDER_DATA",data);
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: EDIT_CLIENT_REMINDER, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const singleClientReminder = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('clientreminder/single', postData
    ).then(({data}) => {
      console.log("CLIENT_REMINDER_DATA",data);
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SINGLE_CLIENT_REMINDER, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const deleteClientReminder = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('clientreminder/delete', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: DELETE_CLIENT_REMINDER, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

