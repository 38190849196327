import {
   ADD_STORE_USER_SUPPLY,
   EDIT_STORE_USER_SUPPLY,
   SINGLE_STORE_USER_SUPPLY,
   FETCH_STORE_USER_SUPPLY,
   DELETE_STORE_USER_SUPPLY
  }
from '../constants/ActionTypes';

const INIT_STATE = {
  addStoreUserSupply: null,
  editStoreUserSupply: null,
  singleStoreUserSupply: null,
  storeUserSupplyData: null,
  deleteStoreUserSupply: null
};

export default (state = INIT_STATE, action) => {
    switch(action.type) {
      case ADD_STORE_USER_SUPPLY:
               return {
                 ...state,
               addStoreUserSupply: action.payload
            }
      case EDIT_STORE_USER_SUPPLY:
                 return {
                 ...state,
                 editStoreUserSupply: action.payload
          }

      case SINGLE_STORE_USER_SUPPLY:
            return {
              ...state,
              singleStoreUserSupply: action.payload
        }
      case FETCH_STORE_USER_SUPPLY:
               return {
                 ...state,
               storeUserSupplyData: action.payload
      }
      case DELETE_STORE_USER_SUPPLY:
            return {
              ...state,
              deleteStoreUserSupply: action.payload
      }
    default:
          return state;
    }
}
