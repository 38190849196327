import {
  FETCH_ERROR,
  CREATE_USER_SUCCESS,
  FETCH_USERS,
  INIT_URL,
  SINGLE_USER,
  EDIT_USER,
  FORGOT_PASS
} from "../constants/ActionTypes";
import axios from 'util/Api'

import {GENERAL_ERROR} from "../constants/values";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};


export const createUser = (postData) => {
  return (dispatch) => {
    axios.post('account/register', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: CREATE_USER_SUCCESS, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const getUsers = (postData) => {
  return (dispatch) => {
    axios.post('admin/users', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_USERS, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};


export const singleUser = (userId) => {
  return (dispatch) => {
    axios.post('account/userinfo', {
        userId: userId,
  	    langLoc:"el",
        fromAdmin: true
      }
    ).then(({data}) => {
      if (data.userInfo){
        dispatch({type: SINGLE_USER, payload: data.userInfo});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const editUser = (postData) => {
  return (dispatch) => {
    axios.post('account/edit',postData).then(({data}) => {
      if (data){
        dispatch({type: EDIT_USER, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const forgotPass = (email) => {
  return (dispatch) => {
    axios.post('account/forgot', {
        email: email,
  	    langLoc:"el",
      }
    ).then(({data}) => {
      if (data){
        dispatch({type: FORGOT_PASS, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};
