import {
   ADD_VEHICLE_WASH,
   EDIT_VEHICLE_WASH,
   SINGLE_VEHICLE_WASH,
   FETCH_VEHICLES_WASH,
   DELETE_VEHICLE_WASH
  }
from '../constants/ActionTypes';

const INIT_STATE = {
  addVehicleWash: null,
  editVehicleWash: null,
  singleVehicleWash: null,
  vehiclesWashData: null,
  deleteVehicleWash: null
};

export default (state = INIT_STATE, action) => {
    switch(action.type) {
      case ADD_VEHICLE_WASH:
               return {
                 ...state,
               addVehicleWash: action.payload
            }
      case EDIT_VEHICLE_WASH:
                 return {
                 ...state,
                 editVehicleWash: action.payload
          }

      case SINGLE_VEHICLE_WASH:
            return {
              ...state,
              singleVehicleWash: action.payload
        }
      case FETCH_VEHICLES_WASH:
               return {
                 ...state,
               vehiclesWashData: action.payload
      }
      case DELETE_VEHICLE_WASH:
            return {
              ...state,
              deleteVehicleWash: action.payload
      }
    default:
          return state;
    }
}
