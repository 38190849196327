import {
   ADD_VEHICLE,
   EDIT_VEHICLE,
   FETCH_VEHICLES,
   SINGLE_VEHICLE
  }
from '../constants/ActionTypes';

const INIT_STATE = {
  addVehicle: null,
  editVehicle: null,
  vehiclesData: null,
  singleVehicle: null
};

export default (state = INIT_STATE, action) => {
    switch(action.type) {
      case ADD_VEHICLE:
               return {
                 ...state,
               addVehicle: action.payload
            }
      case EDIT_VEHICLE:
                 return {
                 ...state,
                 editVehicle: action.payload
          }
      case FETCH_VEHICLES:
                 return {
                 ...state,
                 vehiclesData: action.payload
         }
      case SINGLE_VEHICLE:
            return {
              ...state,
              singleVehicle: action.payload
        }
    default:
          return state;
    }
}
