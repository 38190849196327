import React from 'react';
import ReactDOM from 'react-dom';
import Moment from 'react-moment';

import { initializeFirebase } from './firebase/FirebaseInit';
import registerServiceWorker from './firebase/registerServiceWorker';
import * as Const from './constants/values';

Moment.globalFormat = 'DD/MM/yyyy H:mm';

const rootEl = document.getElementById('app-site');

//FIREBASE CONFIGURATION
if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("./firebase-messaging-sw.js")
    .then(function(registration) {
      if (Const.DEBUG_MODE){
        console.log("Registration successful, scope is:", registration.scope);
      }
    })
    .catch(function(err) {
      if (Const.DEBUG_MODE){
        console.log("Service worker registration failed, error:", err);
      }
    });
}
//END OF FIREBASE CONFIGURATION

// Create a reusable render method that we can call more than once
let render = () => {
  // Dynamically import our main App component, and render it
  const MainApp = require('./MainApp').default;
  ReactDOM.render(
    <MainApp/>,
    rootEl
  );
};

if (module.hot) {
  module.hot.accept('./MainApp', () => {
    const MainApp = require('./MainApp').default;
    render(
      <MainApp/>,
      rootEl
    );
  });
}


registerServiceWorker();
initializeFirebase();


render();
