import {ADD_PUSH_NOTIFICATION, FETCH_PUSH_NOTIFICATIONS } from '../constants/ActionTypes';

const INIT_STATE = {
  addPushNotification: null,
  pushNotifications: null
};

export default (state = INIT_STATE, action) => {
  switch(action.type) {
   case ADD_PUSH_NOTIFICATION:
       return {
           ...state,
           addPushNotification: action.payload
       }
   case FETCH_PUSH_NOTIFICATIONS:
        return {
            ...state,
            pushNotifications: action.payload
        }
  default:
        return state;
  }
}
