import {FETCH_CLIENT_OFFERS,
  CREATE_CLIENT_OFFER,
  EDIT_CLIENT_OFFER,
  SINGLE_CLIENT_OFFER,
  DELETE_CLIENT_OFFER} from '../constants/ActionTypes';

const INIT_STATE = {
  clientOffers: null,
  createClientOffer: null,
  editClientOffer: null,
  singleClientOffer: null,
  deleteClientOffer: null
};

export default (state = INIT_STATE, action) => {
  switch(action.type) {
   case FETCH_CLIENT_OFFERS:
       return {
           ...state,
           clientOffers: action.payload
       }
   case CREATE_CLIENT_OFFER:
        return {
            ...state,
            createClientOffer: action.payload
        }
   case EDIT_CLIENT_OFFER:
         return {
             ...state,
             editClientOffer: action.payload
         }
  case SINGLE_CLIENT_OFFER:
         return {
             ...state,
             singleClientOffer: action.payload
         }
 case DELETE_CLIENT_OFFER:
          return {
            ...state,
            deleteClientOffer: action.payload
          }
  default:
        return state;
  }
}
