import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SHOW_MESSAGE,
  ADD_VEHICLE_TIRES_CHECK,
  EDIT_VEHICLE_TIRES_CHECK,
  SINGLE_VEHICLE_TIRES_CHECK,
  FETCH_VEHICLES_TIRES_CHECK,
  DELETE_VEHICLE_TIRES_CHECK
} from "../constants/ActionTypes";
import axios from 'util/Api'

import {GENERAL_ERROR} from "../constants/values";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const getVehiclesTiresCheck = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('vehicleTiresCheck/all', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: FETCH_VEHICLES_TIRES_CHECK, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const addVehicleTiresCheck = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('vehicleTiresCheck/add', postData
    ).then(({data}) => {
      console.log(data);
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: ADD_VEHICLE_TIRES_CHECK, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const editVehicleTiresCheck = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('vehicleTiresCheck/edit', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: EDIT_VEHICLE_TIRES_CHECK, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const singleVehicleTiresCheck = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('vehicleTiresCheck/single', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SINGLE_VEHICLE_TIRES_CHECK, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const deleteVehicleTiresCheck = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('vehicleTiresCheck/delete', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: DELETE_VEHICLE_TIRES_CHECK, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};
