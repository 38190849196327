import {
   ADD_VEHICLE_TIRES_CHECK,
   EDIT_VEHICLE_TIRES_CHECK,
   SINGLE_VEHICLE_TIRES_CHECK,
   FETCH_VEHICLES_TIRES_CHECK,
   DELETE_VEHICLE_TIRES_CHECK
  }
from '../constants/ActionTypes';

const INIT_STATE = {
  addVehicleTiresCheck: null,
  editVehicleTiresCheck: null,
  singleVehicleTiresCheck: null,
  vehiclesTiresCheckData: null,
  deleteVehicleTiresCheck: null
};

export default (state = INIT_STATE, action) => {
    switch(action.type) {
      case ADD_VEHICLE_TIRES_CHECK:
               return {
                 ...state,
               addVehicleTiresCheck: action.payload
            }
      case EDIT_VEHICLE_TIRES_CHECK:
                 return {
                 ...state,
                 editVehicleTiresCheck: action.payload
          }

      case SINGLE_VEHICLE_TIRES_CHECK:
            return {
              ...state,
              singleVehicleTiresCheck: action.payload
        }
      case FETCH_VEHICLES_TIRES_CHECK:
               return {
                 ...state,
               vehiclesTiresCheckData: action.payload
      }
      case DELETE_VEHICLE_TIRES_CHECK:
            return {
              ...state,
              deleteVehicleTiresCheck: action.payload
      }
    default:
          return state;
    }
}
