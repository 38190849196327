import {REPORT_CLIENT_DATA,REPORT_SERVICE_DATA} from '../constants/ActionTypes';

const INIT_STATE = {
  clientReportData: null,
  serviceReportData: null
};

export default (state = INIT_STATE, action) => {
  switch(action.type) {
   case REPORT_CLIENT_DATA:
       return {
           ...state,
           clientReportData: action.payload
       }
   case REPORT_SERVICE_DATA:
        return {
            ...state,
            serviceReportData: action.payload
        }
  default:
        return state;
  }
}
